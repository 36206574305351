import React, { useState } from "react";
import { FlightData } from "../../StaticData";
import { SmallText } from "../../../components/texts/Texts";
import BidsGraph from "./BidsGraph";
import {
  DimsButton,
  PrimaryButton,
  PrimaryGreenButton,
  SecondaryButton,
} from "../../../components/buttons/Buttons";
import ViewDetails from "./ViewDetails";
import CancelBidModal from "./CancelBidModal";
import BidDetailsModal from "../../modals/BidDetails";
import { useNavigate } from "react-router-dom";

const TripWithFriends = ({
  trips,
  bidsGraph,
  setBidsGraph,
  viewDetail,
  setViewDetail,
}) => {
  const [pause, setPause] = useState(false);
  const [cancelBid, setCancelBid] = useState(false);
  const [bidDetail, setBidDetail] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      {viewDetail === true ? (
        <ViewDetails />
      ) : bidsGraph === true ? (
        <div
          // style={{ opacity: 0.7, pointerEvents: "none" }}
          className="container"
        >
          <div className="row mt-3">
            <div className="col-sm-8 graph_bg br_4 p-sm-4 p-2">
              <div
                className={`${
                  pause === true && "not_active"
                } d-flex align-items-center justify-content-between`}
              >
                <p
                  style={{ color: "#C9C9C9" }}
                  className={` ${"fw_500"}  fs_14 `}
                >
                  Trip With Friends
                </p>
                <p style={{ color: "#838383" }} className={` ${""}  fs_10 `}>
                  Exp 07 July
                </p>
              </div>
              <div
                className={`${
                  pause === true && "not_active"
                } container-fluid p-0 my-3`}
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div>
                      <p
                        style={{ color: "#C9C9C9" }}
                        className={` ${"fw_500"}  fs_10 `}
                      >
                        MALINDO AIR
                      </p>
                      <p
                        style={{ color: "#838383" }}
                        className={` ${""}  fs_10 fw_500 `}
                      >
                        FLIGHT0423045
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="d-flex align-items-center flex-wrap">
                      <div>
                        <p
                          style={{ color: "#838383" }}
                          className={` ${""}  fs_10 `}
                        >
                          07 July 2023 - 10 July 2023
                        </p>
                        <div className="d-flex align-items-center pointer">
                          <p
                            onClick={() => setViewDetail(true)}
                            style={{ color: "#838383" }}
                            className={` ${""}  fs_10 `}
                          >
                            Business | 2 Adults 1 kids |
                          </p>
                          <p
                            style={{ color: "#C9C9C9" }}
                            className={` ${""}  fs_10 fw_500 ms-1 `}
                          >
                            View Details
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BidsGraph pause={pause} />
              <div className={`${pause === true && "not_active"} row`}>
                <div className="col-lg-3">
                  <div>
                    <div
                      style={{ gap: "8px" }}
                      className="d-flex align-items-center"
                    >
                      <p
                        style={{ color: "#838383" }}
                        className={` ${""}  fs_10 `}
                      >
                        High:
                      </p>
                      <p
                        style={{ color: "#C9C9C9" }}
                        className={` ${""}  fs_11 `}
                      >
                        $1920
                      </p>
                    </div>
                    <div
                      style={{ gap: "8px" }}
                      className="d-flex align-items-center mt-1"
                    >
                      <p
                        style={{ color: "#838383" }}
                        className={` ${""}  fs_10 `}
                      >
                        Low:
                      </p>
                      <p
                        style={{ color: "#C9C9C9" }}
                        className={` ${""}  fs_11 `}
                      >
                        $2100
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8">
                  <div>
                    <div
                      style={{ gap: "8px" }}
                      className="d-flex align-items-center"
                    >
                      <p
                        style={{ color: "#838383" }}
                        className={` ${""}  fs_10 `}
                      >
                        Current Price:
                      </p>
                      <p
                        style={{ color: `var(--green_active)` }}
                        className={` ${""}  fs_11 `}
                      >
                        $2150
                      </p>
                    </div>
                    <div
                      style={{ gap: "8px" }}
                      className="d-flex align-items-center mt-1"
                    >
                      <p
                        style={{ color: "#838383" }}
                        className={` ${""}  fs_10 `}
                      >
                        Your Bid:
                      </p>
                      <p
                        style={{ color: "orange" }}
                        className={` ${""}  fs_11 `}
                      >
                        $2100
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div
                  className={`${
                    pause === true ? "col-sm-6" : "col-sm-4"
                  }  mt-sm-4 mt-3`}
                >
                  <SecondaryButton
                    onClick={() => setPause(!pause)}
                    title={`${pause === true ? "Resume" : "Pause Now"} `}
                    color="white"
                    classNames="w-100"
                  />
                </div>
                <div
                  className={`${
                    pause === true && "d-none"
                  } col-sm-8 mt-sm-4 mt-3`}
                >
                  <PrimaryGreenButton
                    onClick={() => navigate("/payment")}
                    title="Buy Now @ Current Price"
                    classNames="w-100"
                  />
                </div>
                <div
                  className={`${
                    pause === false && "d-none"
                  } col-sm-6 mt-sm-4 mt-3`}
                >
                  <DimsButton
                    title="Cancel Bid"
                    classNames="w-100"
                    onClick={() => setCancelBid(true)}
                  />
                </div>
              </div>
              <div className="row ">
                <div className={`${pause === true && "d-none"} col-sm-6 mt-3`}>
                  <PrimaryButton
                    onClick={() => setBidDetail(true)}
                    title="Update Bid"
                    color="Update Bid"
                    classNames="w-100"
                  />
                </div>
                <div className={`${pause === true && "d-none"} col-sm-6 mt-3`}>
                  <DimsButton
                    title="Cancel Bid"
                    classNames="w-100"
                    onClick={() => setCancelBid(true)}
                  />
                </div>
              </div>
              <p style={{ color: "#838383" }} className={` ${""}  fs_10 `}></p>
            </div>
            {pause === false && (
              <div className="col-sm-4">
                <div className="border_style_1 p-2">
                  <p className="fs_10 fw_500 my-2">Top 10 Bid</p>
                  {[...Array(10).keys()].map((data, i) => (
                    <div
                      key={i}
                      className="graph_bg p-1 mb-2 br_4 d-flex align-items-center justify-content-between p-3"
                    >
                      <p className="fs_11 fw_500 text-white">$1,050.50</p>
                      <p
                        style={{ color: `var(--green_active)` }}
                        className={` ${""}  fs_11 `}
                      >
                        $2150
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {FlightData.map((flight, i) => (
            <div
              onClick={() => setBidsGraph(true)}
              key={i}
              className="border_style_1 p-2 br_4 mt-1 pointer"
            >
              <div className="d-flex  justify-content-between">
                <div>
                  <div
                    style={{ gap: "5px" }}
                    className="d-flex align-items-center"
                  >
                    <SmallText
                      title={flight.FlightName}
                      classNames={`${"fw_500"}`}
                    />
                    <p className="fs_10 dim_txt">{flight.flightNumber}</p>
                  </div>
                  <p className="fs_10 dim_txt">
                    {flight.date}-{flight.date}
                  </p>
                  <p className="fs_10 dim_txt">Expiry-07 Wednesday,2023</p>
                </div>
                <div className="d-flex align-items-end flex-column">
                  <SmallText
                    title={`$ ${flight.minPrice}`}
                    classNames={`${"fw_700"}`}
                  />
                  {trips === "cancel_trips_with_friends" ? (
                    <p className="fs_11 mt-1 react_col reactivate_bg px-2 fw_500">
                      Reactivate
                    </p>
                  ) : (
                    <p className="fs_10 primary_txt fw_500">-120</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      <CancelBidModal open={cancelBid} setOpen={setCancelBid} />
      <BidDetailsModal open={bidDetail} setOpen={setBidDetail} />
    </>
  );
};

export default TripWithFriends;
