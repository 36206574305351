import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle } from "../../modals/modalUtitlities";
import "../../modals/Modals.css";
import { SmallHeadingText } from "../../../components/texts/Texts";
import cancel from ".././../../assets/images/cancel.png";
import { PrimaryButton } from "../../../components/buttons/Buttons";

export default function CancelBidModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <div className="relative p-4">
            <div className="d-flex align-items-center justify-content-center  flex-column   ">
              <img src={cancel} alt="cancel" width="70px" />{" "}
              <SmallHeadingText title="Cancel " classNames="mt-3 fw_700" />
              <p className=" fs_14">Are You sure you want to Cancel ?</p>
              <PrimaryButton title="Yes" classNames="w-100 mt-3" />
            </div>

            <i
              className="fa-solid fa-xmark cross_icon_modal"
              onClick={handleClose}
            ></i>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
