import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { SmallHeadingText, SmallText } from "../../components/texts/Texts";
import { FlightData } from "../StaticData";
import air_icon from "../../assets/images/air_icon.png";
import "./flightDetails.css";
import { TextField1 } from "../../components/fields/TextFields";
import { PrimaryButton, ThirdButton } from "../../components/buttons/Buttons";
import { useState } from "react";
import BidDetailsModal from "../modals/BidDetails";

const FlighDetails = () => {
  const [bidDetails, setBidDetails] = useState(false);

  return (
    <>
      <div className="container mt-3">
        <div className="row ">
          <Header />
        </div>
        <div className="row mt-3 ">
          <SmallHeadingText title="Complete Your Booking" />

          <div className="col-sm-9">
            <div className="table-responsive mt-3">
              <table className="table flights-table">
                <tbody>
                  {FlightData.slice(0, 2).map((flight, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className="main_table_box border_style_tr_1 pt-3 pointer"
                        onClick={() => setBidDetails(true)}
                      >
                        <td className="pt-2 pb-0">
                          <img src={flight.image} alt="flight_image" />
                          <p className="fs_12 dim_txt fw_500">
                            {flight.FlightName}
                          </p>
                          <p className="fs_12 dim_txt ">
                            {flight.flightNumber}
                          </p>
                        </td>
                        <td className="pt-3 pb-0">
                          <p className="fs_12 dim_txt">{flight.date}</p>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="d-flex align-items-center">
                                <SmallText
                                  title={flight.departTime}
                                  classNames={`${"fw_700 fs_14"}`}
                                />
                                <p className="ms-1 fs_12">Sydney</p>
                              </div>
                              <p className="fs_11 dim_txt">
                                Chhatrapti Shivaji International
                              </p>

                              <p className="fs_12 dim_txt">
                                {flight.flightNumber}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className="pt-3 pb-0">
                          <img src={air_icon} alt="air_icon" />
                          <p className="fs_12 fw_700">{flight.timing}</p>
                        </td>
                        <td className="pt-3 pb-0">
                          <p className="fs_12 dim_txt">{flight.date}</p>
                          <div className="d-flex align-items-center">
                            <SmallText
                              title={flight.arrivalTime}
                              classNames={`${"fw_700 fs_14"}`}
                            />
                            <p className="ms-1 fs_12">Bengalur</p>
                          </div>

                          <p className="fs_12 dim_txt">{flight.to}</p>
                        </td>
                      </tr>
                      <tr className="border_style_tr_2 pb-">
                        <td>
                          <div
                            style={{ gap: "10px" }}
                            className="d-flex align-items-center"
                          >
                            <i className="fa-solid fa-briefcase"></i>
                            <p className="fs_11 dim_txt ">{`Economy- ${flight.economy_seat} seats`}</p>
                            <i className="fa-solid fa-briefcase"></i>
                            <p className="fs_11 dim_txt ">15kgs checkin</p>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="p-2 border_style_1">
              <SmallHeadingText title="Travellers Details" />
              <div className="d-flex align-items-center justify-content-between mt-3">
                <SmallText title="Adult" classNames="fw_700 fs_14" />
                <div className="d-flex align-items-center primary_txt pointer">
                  <i className="fa-solid fa-plus fs_11 fw_700"></i>
                  <SmallText
                    title="Add More"
                    classNames={`${"fw_700"} ms-1 `}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4  mt-2">
                  <TextField1 label="First Name" placeHolder="First Name" />
                </div>
                <div className="col-sm-4  mt-2">
                  <TextField1 label="Last Name" placeHolder="Last Name" />
                </div>
                <div className="col-sm-4  mt-2">
                  <TextField1
                    label="Gender Specified on Your Mail id"
                    placeHolder="Gender Specified on Your Mail id"
                  />
                </div>
                <div className="col-sm-4  mt-2">
                  <TextField1
                    label="Date Of Birth"
                    placeHolder="Date Of Birth"
                    iconDiv={
                      <div className="icon_div pointer dim_txt">
                        <i className="fa-solid fa-calendar-days"></i>
                      </div>
                    }
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="d-flex align-items-center justify-content-between ">
                  <SmallText title="Chilren " classNames="fw_700 fs_14" />
                  <div className="d-flex align-items-center primary_txt pointer">
                    <i className="fa-solid fa-plus fs_11 fw_700"></i>
                    <SmallText
                      title="Add More"
                      classNames={`${"fw_700"} ms-1 `}
                    />
                  </div>
                </div>
                <div className="col-sm-4  mt-2">
                  <TextField1 label="First Name" placeHolder="First Name" />
                </div>
                <div className="col-sm-4  mt-2">
                  <TextField1 label="Last Name" placeHolder="Last Name" />
                </div>

                <div className="col-sm-4  mt-2">
                  <TextField1
                    label="Date Of Birth"
                    placeHolder="Date Of Birth"
                    iconDiv={
                      <div className="icon_div pointer dim_txt">
                        <i className="fa-solid fa-calendar-days"></i>
                      </div>
                    }
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="d-flex align-items-center justify-content-between ">
                  <SmallText title="Infant " classNames="fw_700 fs_14" />
                  <div className="d-flex align-items-center primary_txt pointer">
                    <i className="fa-solid fa-plus fs_11 fw_700"></i>
                    <SmallText
                      title="Add More"
                      classNames={`${"fw_700"} ms-1 `}
                    />
                  </div>
                </div>
                <div className="col-sm-4  mt-2">
                  <TextField1 label="First Name" placeHolder="First Name" />
                </div>
                <div className="col-sm-4  mt-2">
                  <TextField1 label="Last Name" placeHolder="Last Name" />
                </div>
                <div className="col-sm-4  mt-2">
                  <TextField1
                    label="Date Of Birth"
                    placeHolder="Date Of Birth"
                    iconDiv={
                      <div className="icon_div pointer dim_txt">
                        <i className="fa-solid fa-calendar-days"></i>
                      </div>
                    }
                  />
                </div>
                <div className="col-sm-4  mt-2">
                  <TextField1
                    label="Infant Age at the time of Travel"
                    placeHolder="Age"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5">
                  <PrimaryButton title="Save" classNames="my-5 w-100" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mt-3">
            <div className="border_style_1 p-2">
              <SmallText title="Fare Summary " classNames="fw_700 fs_14" />
              <p className="fs_11 dim_txt">2 Adults | 2 Childs |1 infant</p>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <p className="fs_12 dim_txt fw_500">Adult(2 x 4,194)</p>
                <p className="fs_12 dim_txt fw_500">$2150</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <p className="fs_12 dim_txt fw_500">Adult(2 x 4,194)</p>
                <p className="fs_12 dim_txt fw_500">$2150</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <p className="fs_12 dim_txt fw_500">Adult(2 x 4,194)</p>
                <p className="fs_12 dim_txt fw_500">$2150</p>
              </div>
              <hr />{" "}
              <div className="d-flex align-items-center justify-content-between ">
                <p className="fs_12 dim_txt ">Taxes</p>
                <p className="fs_12 dim_txt ">$100</p>
              </div>{" "}
              <div className="d-flex align-items-center justify-content-between mt-2">
                <p className="fs_12 dim_txt ">SubTotal</p>
                <p className="fs_12 dim_txt ">$100</p>
              </div>{" "}
              <div className="d-flex align-items-center justify-content-between mt-2">
                <p className="fs_12 dim_txt ">AVG per person</p>
                <p className="fs_12 dim_txt ">$100</p>
              </div>{" "}
              <div className="d-flex align-items-center justify-content-between mt-2">
                <p className="fs_12 dim_txt ">Total</p>
                <p className="fs_12 dim_txt ">$100</p>
              </div>
              <PrimaryButton title="Add to Bid List" classNames="w-100 mt-3" />
              <ThirdButton title="Book Now" classNames="w-100 mt-2" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <BidDetailsModal open={bidDetails} setOpen={setBidDetails} />
    </>
  );
};

export default FlighDetails;
