import React from "react";
import { SmallText } from "../../../components/texts/Texts";
import air_icon from "../../../assets/images/air_icon.png";

const ViewDetailMenu = [
  {
    title: "Soutwest: (Flight04206103)",
    icon: <i className="fa-sharp fa-solid fa-plane-departure  me-2 fs_12" />,
  },
  {
    title: " Economy Class ",
    icon: <i className="fa-solid fa-chair  me-2 fs_12" />,
  },
  {
    title: "5h 50m . 1 Stop at Hong Kong",
    icon: <i className="fa-regular fa-clock  me-2 fs_12" />,
  },
  {
    title: "Layover 1hr 40 mini",
    icon: <i className="fa-solid fa-circle  me-2 fs_12" />,
  },
  {
    title: "Cabin baggage 7 kg (1 piece) ",
    icon: <i className="fa-solid fa-bag-shopping  me-2 fs_12" />,
  },
  {
    title: "Cabin Luggage (1 piece) ",
    icon: <i className="fa-solid fa-suitcase  me-2 fs_12" />,
  },
  {
    title: "0123456789",
    icon: <i className="fa-solid fa-phone  me-2 fs_12" />,
  },
];

const ViewDetails = () => {
  // console.log("hy");
  return (
    <>
      <div className="border_style_1 mt-3 ">
        <div className="table-responsive ">
          <table className="table flights-table mb-2 mb-sm-0">
            <tbody>
              <React.Fragment>
                <tr className="main_table_box  py-3 ">
                  <td className="py-3">
                    <div className="d-flex align-items-center">
                      {/* <i className="fa-sharp fa-solid fa-plane-departure dimBorder_texxt me-3 fs_12"></i> */}
                      <div>
                        <SmallText
                          title="Depart"
                          classNames={`${"fw_700 fs_14"}`}
                        />
                        <p
                          style={{ opacity: 0.7 }}
                          className="fs_11 dim_txt  text-center    "
                        >
                          Fri, 07 July, 2023
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="py-3">
                    <div className="d-flex align-items-center">
                      <div>
                        <SmallText
                          title="Southwest"
                          classNames={`${"fw_700 fs_14"}`}
                        />
                        <p
                          // style={{ opacity: 0.7 }}
                          className="fs_10 dim_txt      "
                        >
                          {" "}
                          Chhatrapati Shivai International Airport, Sydney,
                          India 3
                        </p>
                        <p>10:00 PM</p>
                      </div>
                    </div>
                  </td>

                  <td className="py-3 ">
                    <div className="d-flex align-items-center justify-content-center">
                      <img src={air_icon} alt="air_icon" />
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      {" "}
                      <p
                        style={{ display: "inline-block" }}
                        className="fs_11 mt-1 primary_txt primary_txt_bg px-2 fw_500 br_4"
                      >
                        1 stop
                      </p>
                    </div>
                  </td>
                  <td className="py-3">
                    <div className="d-flex align-items-center">
                      <div>
                        <SmallText
                          title="Bengalauru"
                          classNames={`${"fw_700 fs_14"}`}
                        />
                        <p
                          // style={{ opacity: 0.7 }}
                          className="fs_10 dim_txt      "
                        >
                          {" "}
                          Indira Gandhi International Airport, Bengaluru, India
                          3
                        </p>
                        <p>3:50 PM</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            </tbody>
          </table>
        </div>
        <div className="container-fluid">
          <div className="row mb-3">
            {ViewDetailMenu.map((data, i) => (
              <div key={i} className="col-sm-3 p-1">
                <p
                  style={{ opacity: 0.7 }}
                  className="fs_10 dim_txt   fw_500  "
                >
                  {data.icon}
                  {data.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container-fluid p-0 mt-3">
        <SmallText
          title="Travellers Details"
          classNames={`${"fw_700 fs_14 mt-4"}`}
        />
        <div className="row">
          <div className="col-sm-4 mt-3">
            <div
              style={{ gap: "15px" }}
              className="traverler_deatil_container  trav_det_back d-flex align-items-center p-2"
            >
              <i style={{ opacity: 0.7 }} class="fa-solid fa-user dim_txt"></i>
              <div>
                {" "}
                <SmallText title="Alice Butler" classNames={`${"fw_700 "}`} />
                <p
                  style={{ opacity: 0.7 }}
                  className="fs_11 dim_txt  fw_500   "
                >
                  Adult
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="col-sm-4 mt-3">
            <div
              style={{ gap: "15px" }}
              className="traverler_deatil_container  trav_det_back d-flex align-items-center p-2"
            >
              <i style={{ opacity: 0.7 }} class="fa-solid fa-user dim_txt"></i>
              <div>
                {" "}
                <SmallText title="Alice Butler" classNames={`${"fw_700 "}`} />
                <p
                  style={{ opacity: 0.7 }}
                  className="fs_11 dim_txt  fw_500   "
                >
                  Adult
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="col-sm-4 mt-3">
            <div
              style={{ gap: "15px" }}
              className="traverler_deatil_container  trav_det_back d-flex align-items-center p-2"
            >
              <i style={{ opacity: 0.7 }} class="fa-solid fa-user dim_txt"></i>
              <div>
                {" "}
                <SmallText title="Alice Butler" classNames={`${"fw_700 "}`} />
                <p
                  style={{ opacity: 0.7 }}
                  className="fs_11 dim_txt  fw_500   "
                >
                  Adult
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetails;
