import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle } from "./modalUtitlities";
import "./Modals.css";
import { SmallHeadingText } from "../../components/texts/Texts";
import { PrimaryButton } from "../../components/buttons/Buttons";
import { TextField1 } from "../../components/fields/TextFields";
import NewPasswordModal from "./NewPasswordModa";

export default function PasswordModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSendOTP = () => {
    // Send OTP logic
    // You can add your own logic here to send the OTP to the provided email
    console.log("Send OTP to:", email);
    setNewPassword(true);
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <div className="relative p-4">
            <SmallHeadingText title="Forgot Password" />
            <p className="lead fs_12">
              Please enter your email address Lo reset your password.
            </p>
            <TextField1
              label="Email"
              type="email"
              className="email-input"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              labelclassNames="mt-2"
            />
            <PrimaryButton
              title="Send OTP"
              classNames="w-100 mt-4"
              onClick={handleSendOTP}
            />
            <i
              className="fa-solid fa-xmark cross_icon_modal"
              onClick={handleClose}
            ></i>
          </div>
        </Box>
      </Modal>
      <NewPasswordModal open={newPassword} setOpen={setNewPassword} />
    </div>
  );
}
