import Slider from "@mui/material/Slider";
import "./Range.css";
import { styled } from "@mui/material/styles";

const CustomSlider = styled(Slider)({
  color: `var(--primary)`, // Customize the color of the track and thumb to red

  // Customize the styles for hover state
  "&:hover": {
    color: `var(--primary)`, // Change the color on hover
  },

  // Customize the styles for focus state
  "&:focus": {
    outline: "none", // Remove the default focus outline
    boxShadow: "0 0 5px blue", // Add a custom box-shadow on focus
  },

  // Customize the styles for active state
  "&:active": {
    color: `var(--primary)`, // Change the color on active state
  },

  // Customize the styles for disabled state
  "&:disabled": {
    opacity: 0.5, // Reduce opacity when disabled
    cursor: "not-allowed", // Change cursor to not-allowed
  },

  // Customize the color of the value label text
  "& .MuiSlider-valueLabel": {
    color: `#000`,
  },
});

export const Range = ({ label, labelclassNames, iconDiv, labelIcon }) => {
  const marks = [
    {
      value: 0,
      label: "0",
    },

    {
      value: 100,
      label: "99%",
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }
  return (
    <>
      <p className={`${labelclassNames && labelclassNames} fs_11 fw_700 mb-1`}>
        {label}
        {labelIcon && labelIcon}
      </p>
      <div className="relative w-100">
        {/* <input type="range" min="1" max="100" className="w-100" /> */}
        <CustomSlider
          aria-label="Custom marks"
          defaultValue={20}
          getAriaValueText={valuetext}
          step={10}
          valueLabelDisplay="auto"
          marks={marks}
        />
        {iconDiv}
      </div>
    </>
  );
};
