import React from "react";
import "./footer.css";
import { MediumHeadingText, SmallBoldText } from "../texts/Texts";
import { UpperFlightData } from "../../pages/StaticData";
import footer_logo from "../../assets/images/footer_logo.png";

const Footer = () => {
  return (
    <div className="mt-5 footer_bg pt-5">
      <div className="container">
        <div className="row gy-5">
          {UpperFlightData.map((flight, index) => (
            <div key={index} className="col-sm-4">
              <div
                style={{ gap: "20px" }}
                className="d-flex align-items-center flex-wrap"
              >
                <div className="icon_bg_footer">
                  <i className={flight.iconClass}></i>
                </div>
                <div>
                  <div
                    style={{ gap: "15px" }}
                    className="d-flex align-items-center flex-wrap mb-1"
                  >
                    <MediumHeadingText
                      title={flight.title}
                      classNames="text-white"
                    />
                    <p className="dimBorder_texxt lead font-italic fs_15">
                      {flight.text}
                    </p>
                  </div>
                  <p className="text-white lead fs_12 opac_07">
                    {flight.description}
                  </p>
                  <p className="fs_12 lead font-italic pt-2"></p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-4 ">
          <div className="col-sm-3">
            <img
              src={footer_logo}
              alt="footer_logo"
              style={{ width: "120px" }}
            />
            <p className="text-white lead fs_12 opac_07 mt-3">
              Welcome to Trippy Bids, your ultimate gateway to intelligent and
              strategic booking. Experience the power of making smart decisions
              and securing the best deals effortlessly.
            </p>
            <div
              style={{ gap: "10px" }}
              className="d-flex align-items-center mt-4"
            >
              <div className="footer_social_icon_divb dim_bg">
                <i className="fa-brands fa-facebook-f"></i>
              </div>
              <div
                style={{ padding: "4px 9px" }}
                className="footer_social_icon_divb dim_bg"
              >
                <i className="fa-brands fa-twitter"></i>
              </div>{" "}
              <div
                style={{ padding: "4px 8px" }}
                className="footer_social_icon_divb dim_bg"
              >
                <i className="fa-brands fa-youtube"></i>
              </div>{" "}
              <div
                style={{ padding: "4px 9px" }}
                className="footer_social_icon_divb dim_bg"
              >
                <i className="fa-brands fa-instagram"></i>
              </div>
            </div>
          </div>
          <div className="col-sm-2"></div>
          <div className="col-sm-7">
            <div className="row">
              <div className="col-sm-4">
                <SmallBoldText
                  title="Quick Links"
                  classNames="text-white mt-4 "
                />
                <p className="text-white lead fs_12 opac_07 mt-2  ">About</p>
                <p className="text-white lead fs_12 opac_07 mt-2  ">FAQ's</p>
                <p className="text-white lead fs_12 opac_07 mt-2  ">Contact</p>
              </div>
              <div className="col-sm-4">
                {" "}
                <SmallBoldText title="Others" classNames="text-white mt-4 " />
                <p className="text-white lead fs_12 opac_07 mt-2  ">
                  Privacy Policy
                </p>
                <p className="text-white lead fs_12 opac_07 mt-2  ">
                  Terms and Conditions
                </p>
              </div>
              <div className="col-sm-4">
                {" "}
                <SmallBoldText
                  title="Contact Us"
                  classNames="text-white mt-4 "
                />
                <div className="text-white ">
                  <div
                    style={{ gap: "10px" }}
                    className="d-flex align-items-center mt-2"
                  >
                    <i className="fa-sharp fa-solid fa-phone-volume text-white lead fs_12 opac_07 "></i>
                    <p className="text-white lead fs_12 opac_07">
                      +61 1234 567
                    </p>
                  </div>{" "}
                  <div
                    style={{ gap: "10px" }}
                    className="d-flex align-items-center mt-2"
                  >
                    <i className="fa-sharp fa-solid fa-envelope  text-white lead fs_12 opac_07"></i>
                    <p className="text-white lead fs_12 opac_07">
                      info@trippybids.com
                    </p>
                  </div>{" "}
                  <div
                    style={{ gap: "10px" }}
                    className="d-flex align-items-center mt-2"
                  >
                    <i className="fa-sharp fa-solid fa-location-dot  text-white lead fs_12 opac_07"></i>
                    <p className="text-white lead fs_12 opac_07">
                      9460 Glenridge Circle Ormond Beach, FL 32174 +61 1234 567
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
        <p className="text-white lead fs_12 opac_07 text-center mt-4 pb-4">
          © Copyright 2023 Trippy Bids, All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
