import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSwitchCss = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-thumb": {
    color: theme.palette.common.white,
    backgroundColor: "red",
    boxShadow: "none",
    "&.Mui-focusVisible": {
      backgroundColor: "red",
      boxShadow: `0px 0px 0px 8px rgba(255, 0, 0, 0.16)`,
    },
    "&:hover": {
      backgroundColor: "red",
    },
    "&.Mui-checked": {
      backgroundColor: "red",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    backgroundColor: "red !important",
  },
}));

const label = { inputProps: { "aria-label": "Switch demo" } };

export const CustomSwitch = () => {
  return <CustomSwitchCss {...label} size="small" />;
};
