import "./textfields.css";

export const TextField1 = ({
  label,
  labelclassNames,
  inputClassNames,
  type,
  maxLength,
  iconDiv,
  placeHolder,
  labelIcon,
}) => {
  return (
    <>
      <p className={`${labelclassNames && labelclassNames} fs_11 fw_700 mb-1`}>
        {label}
        {labelIcon && labelIcon}
      </p>
      <div className="relative">
        <input
          placeholder={placeHolder && placeHolder}
          maxLength={maxLength && maxLength}
          type={type && type}
          className={`${inputClassNames && inputClassNames} textFIeld1 w-100`}
        />
        {iconDiv}
      </div>
    </>
  );
};
