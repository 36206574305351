import React from "react";
import bannerImage from "../../assets/images/bannerImage.jpg";
import {
  MediumHeadingText,
  SmallBoldText,
  SmallHeadingText,
} from "../../components/texts/Texts";
import TripSelector from "./TripSelector";
import "./Home.css";
import { About_Start_Why, How_It_works } from "../StaticData";
import Destinations from "./Destinations";
import Testimonials from "./Testimonials";
import DownLoadApp from "./DownLoadApp";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const Home = () => {
  return (
    <>
      <div className="banner_cont">
        <img
          src={bannerImage}
          alt="baner_img"
          className="img-fluid banner_img"
        />
        <div className="container banner_abs_cont ">
          <Header />
          <div className="row mt-5">
            <h2 className="fw_700">Place Bids &</h2>
            <h2 className="fw_700 mt-1">Book Flights With Ease</h2>
            <p className="mt-3">
              Experience automatic booking as you bid for the best <br /> deals
              available.
            </p>
            <div
              style={{ gap: "10px" }}
              className="d-flex align-items-center mt-3 "
            >
              <SmallBoldText title="Explore Now " />
              <i className="fa-solid fa-arrow-right-long"></i>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="trip_selector_box  ">
            <TripSelector />
          </div>
          <div className="row ">
            {About_Start_Why.map((data, i) => (
              <div key={i} className="col-sm-4 mt-5">
                <SmallHeadingText title={data.heading} />
                <p className="fs_12 ">{data.description}</p>
                <div className="d-flex align-items-center mt-3">
                  <p className="fs_12 primary_txt fw_700 ">Read More</p>
                  <i className="fa-solid fa-arrow-right-long primary_txt ms-2"></i>
                </div>
              </div>
            ))}
          </div>
          <Destinations />
          <div className="row mt-5">
            <MediumHeadingText classNames="text-center" title="How it works" />
            <p className="fs_12 text-center">
              Experience automatic booking as you bid for the best deals
              available.
            </p>
            <div className="col-xl-11 mx-auto">
              <div className="container-fluid">
                <div className="row">
                  {How_It_works.map((data, i) => (
                    <div key={i} className="col-md-6 col-lg-3">
                      <div className="how_its_works_box mt-4">
                        <img
                          src="https://images.pexels.com/photos/994197/pexels-photo-994197.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                          className="img-fluid how_it_wor_image "
                          alt="fdgf"
                        />
                      </div>
                      <div className="how_its_works_text_box ">
                        <h2 className="fw_700 dimBorder_texxt">{i + 1}</h2>
                        <SmallBoldText
                          title={data.heading}
                          classNames="text-white mt-2"
                        />
                        <p className="fs_12 dimBorder_texxt mt-1 lead">
                          {data.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testimonials />
        <DownLoadApp />
        <Footer />
      </div>
    </>
  );
};

export default Home;
