import flight_Image from "../assets/images/flight_Image.png";

export const About_Start_Why = [
  {
    heading: "About Trippy Bids",
    description:
      "Welcome to our app, your ultimate gateway to inteligentand strategic booking. Experience the power of makingsmart decisions and securing the best deals effortiessly.Discover @ world of seamless travel planning and embarkon unforgettable journeys with confidence.",
  },
  {
    heading: "Why Choose Us",
    description:
      "With our innovative notification system, you'll receive timelyupdates as your bid approaches the current ticket price.Stay shead of the game and secure your desired tickets atthe right moment. Get ready for a seamiess and informedbooking experience. Let's make every bid count!",
  },
  {
    heading: "Start Biding Now",
    description:
      "Experience the convenience of placing bids and securingYour desired fights effortiessly. Get ready to embark onYourjourney with confidence and simlicty. Get ready toredefine your booking experience and make every trip amemorable one!",
  },
];

export const How_It_works = [
  {
    heading: "Search Flight",
    description:
      "Discover a world of seamless travel planning and embark on unforgettable journeys.",
  },
  {
    heading: "Login & Signup  ",
    description:
      "Users can easily create an account, login, and search for flights and bid on the website.",
  },
  {
    heading: "Start Biding",
    description:
      "Experience automatic booking asyou bid for the best dealsavailable.",
  },
  {
    heading: "Enjoy Your Journey",
    description:
      "Discover convenience, comfort,and excitement as you embark on your journey to new horizons.",
  },
];

export const UpperFlightData = [
  {
    title: "1800+",
    text: "Daily Flights",
    description: "Fly With Ease: Daily Flights To Your Destination",
    iconClass: "fa-sharp fa-solid fa-plane-departure primary_txt fs_20",
  },
  {
    title: "25+",
    text: "International Destination",
    description: "Explore The World: International Destinations Await",
    iconClass: "fa-solid fa-earth-americas primary_txt fs_20",
  },
  {
    title: "500 M+",
    text: "Happy Customers",
    description: "Satisfied Customers Share Their Positive Experiences",
    iconClass: "fa-sharp fa-solid fa-plane-departure primary_txt fs_20",
  },
];

export const FlightData = [
  {
    icon: "",
    FlightName: "Malindo Air",
    flightNumber: "Flight 042ED2C",
    departTime: "9:30 PM",
    from: "SYD Sydney Australia",
    arrivalTime: "2:50 AM",
    to: "BLR Bengaluru India",
    minPrice: "2,150",
    maxPrice: "6,451",
    timing: "5h 50m - 1 stop",
    economy_seat: 7,
    image: flight_Image,
    date: "Tue, 9 July 2023",
  },
  {
    icon: "",
    FlightName: "AirAsia",
    flightNumber: "Flight A1234XY",
    departTime: "7:00 AM",
    from: "DEL New Delhi India",
    arrivalTime: "12:30 PM",
    to: "DXB Dubai UAE",
    minPrice: "1,800",
    maxPrice: "5,200",
    timing: "5h 30m - Direct",
    economy_seat: 12,
    image: flight_Image,
    date: "thurday, 25 Aug 2023",
  },
  {
    icon: "",
    FlightName: "Emirates",
    flightNumber: "Flight E9876AB",
    departTime: "2:15 PM",
    from: "LHR London UK",
    arrivalTime: "8:45 PM",
    to: "JFK New York USA",
    minPrice: "3,500",
    maxPrice: "9,800",
    timing: "6h 30m - 1 stop",
    economy_seat: 5,
    image: flight_Image,
  },
  {
    icon: "",
    FlightName: "Qatar Airways",
    flightNumber: "Flight Q5678CD",
    departTime: "11:00 PM",
    from: "HKG Hong Kong",
    arrivalTime: "4:30 AM",
    to: "SYD Sydney Australia",
    minPrice: "2,900",
    maxPrice: "7,100",
    timing: "8h 30m - 1 stop",
    economy_seat: 10,
    image: flight_Image,
  },
  {
    icon: "",
    FlightName: "Singapore Airlines",
    flightNumber: "Flight S5432EF",
    departTime: "6:45 AM",
    from: "SIN Singapore",
    arrivalTime: "10:15 AM",
    to: "BKK Bangkok Thailand",
    minPrice: "1,500",
    maxPrice: "4,800",
    timing: "3h 30m - Direct",
    economy_seat: 8,
    image: flight_Image,
  },
];

export const flightsData = [
  {
    airline: "Mlindo Air",
    flightNumber: "Flight 042de103",
    price: "$1000.50",
  },
  {
    airline: "Air XYZ",
    flightNumber: "Flight ABC123",
    price: "$750.00",
  },
  {
    airline: "Fly High Airlines",
    flightNumber: "Flight FH789",
    price: "$850.75",
  },
  {
    airline: "Sky Travelers",
    flightNumber: "Flight ST456",
    price: "$950.00",
  },
  {
    airline: "Airways Express",
    flightNumber: "Flight AE246",
    price: "$620.25",
  },
  {
    airline: "Cloud Wings",
    flightNumber: "Flight CW983",
    price: "$1200.00",
  },
  {
    airline: "Windy Skies",
    flightNumber: "Flight WS555",
    price: "$880.50",
  },
  {
    airline: "Star Airways",
    flightNumber: "Flight SA777",
    price: "$960.35",
  },
  {
    airline: "Aero Fly",
    flightNumber: "Flight AF111",
    price: "$700.90",
  },
  {
    airline: "Swift Airlines",
    flightNumber: "Flight SW333",
    price: "$800.20",
  },
];

export const SideBarMenu = [
  {
    title: "My Profile",
    icon: <i className="fa-solid fa-user"></i>,
    path: "/dashboard/profile",
  },
  {
    title: "My Bids",
    icon: <i className="fa-solid fa-bell"></i>,
    path: "/dashboard/my-bids",
  },
  {
    title: "My Bookings",
    icon: <i className="fa-solid fa-bookmark"></i>,
    path: "/dashboard/my-bookings",
  },
  {
    title: "Notifications",
    icon: <i className="fa-solid fa-bell"></i>,
    path: "/dashboard/notifications",
  },
  {
    title: "Change Password",
    icon: <i className="fa-solid fa-key"></i>,
    path: "/dashboard/change-password",
  },
  {
    title: "Country",
    icon: <i className="fa-solid fa-flag"></i>,
    path: "/dashboard/country",
  },
  {
    title: "My Cards",
    icon: <i className="fa-solid fa-credit-card"></i>,
    path: "/dashboard/my-cards",
  },
  {
    title: "FAQs",
    icon: <i className="fa-solid fa-question"></i>,
    path: "/dashboard/faqs",
  },
  {
    title: "Terms & Conditions",
    icon: <i className="fa-solid fa-file-alt"></i>,
    path: "/dashboard/terms-and-conditions",
  },
  {
    title: "Delete Account",
    icon: <i className="fa-solid fa-trash-alt"></i>,
    path: "/dashboard/delete-account",
  },
  {
    title: "Logout",
    icon: <i className="fa-solid fa-sign-out-alt"></i>,
    path: "/dashboard/logout",
  },
];

export const DefaultSelectData = [
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
];

export const active_bids_list = [
  {
    title: "India-LA",
    price: "$2,150.50",
    bids: "5 Bids",
    discount: "-$120.00",
  },
  {
    title: "USA-UK",
    price: "$1,850.00",
    bids: "3 Bids",
    discount: "-$90.00",
  },
  {
    title: "Canada-Japan",
    price: "$1,450.75",
    bids: "8 Bids",
    discount: "-$50.25",
  },
  {
    title: "Australia-Germany",
    price: "$2,300.25",
    bids: "6 Bids",
    discount: "-$180.50",
  },
  {
    title: "France-Switzerland",
    price: "$2,000.00",
    bids: "4 Bids",
    discount: "-$110.00",
  },
  {
    title: "China-Russia",
    price: "$2,250.75",
    bids: "9 Bids",
    discount: "-$160.25",
  },
  {
    title: "Brazil-Spain",
    price: "$1,950.00",
    bids: "7 Bids",
    discount: "-$75.00",
  },
  {
    title: "Italy-Austria",
    price: "$1,600.50",
    bids: "2 Bids",
    discount: "-$40.00",
  },
  {
    title: "Mexico-Argentina",
    price: "$2,100.25",
    bids: "5 Bids",
    discount: "-$100.75",
  },
  {
    title: "South Africa-Egypt",
    price: "$1,750.00",
    bids: "4 Bids",
    discount: "-$65.50",
  },
  // Add more objects as needed
];

export const GraphData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
