import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle } from "./modalUtitlities";
import "./Modals.css";
import { SmallHeadingText } from "../../components/texts/Texts";
import { PrimaryButton } from "../../components/buttons/Buttons";
import { TextField1 } from "../../components/fields/TextFields";

export default function AddCardModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCVV] = useState("");

  const handleCardNameChange = (event) => {
    setCardName(event.target.value);
  };

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  const handleCVVChange = (event) => {
    setCVV(event.target.value);
  };

  const handleSaveCard = () => {
    // Handle save card logic
    // You can add your own logic here to save the card details
    console.log("Card Name:", cardName);
    console.log("Card Number:", cardNumber);
    console.log("Expiry Date:", expiryDate);
    console.log("CVV:", cvv);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <div className="relative p-4">
            <SmallHeadingText title="Add New Card" />
            <p className="lead fs_12">Please enter your card details.</p>
            <TextField1
              label="Name on Card"
              type="text"
              value={cardName}
              onChange={handleCardNameChange}
              labelclassNames="mt-2"
            />
            <TextField1
              label="Card Number"
              type="text"
              value={cardNumber}
              onChange={handleCardNumberChange}
              labelclassNames="mt-2"
            />
            <div className="row mt-2">
              <div className="col-sm-6">
                <TextField1
                  label="Expiry Date"
                  type="text"
                  value={expiryDate}
                  onChange={handleExpiryDateChange}
                  labelclassNames="mt-0"
                />
              </div>
              <div className="col-sm-6">
                <TextField1
                  label="CVV"
                  type="text"
                  value={cvv}
                  onChange={handleCVVChange}
                  labelclassNames="mt-0"
                />
              </div>
            </div>
            <PrimaryButton
              title="Save Card"
              classNames="w-100 mt-4"
              onClick={handleSaveCard}
            />
            <i
              className="fa-solid fa-xmark cross_icon_modal"
              onClick={handleClose}
            ></i>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
