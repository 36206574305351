import React from "react";
import { MediumHeadingText } from "../../components/texts/Texts";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Destinations = () => {
  const slider = React.useRef(null);

  const navigate = useNavigate();
  return (
    <div className="row mt-5">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          {" "}
          <MediumHeadingText title="Hot Destinations" />
          <p className="fs_12 ">
            Experience automatic booking as you bid for the best deals
            available.
          </p>
        </div>
        <div style={{ gap: "30px" }} className="d-flex align-items-center ">
          <i
            className="pointer fa-solid fa-arrow-left"
            onClick={() => slider?.current?.slickPrev()}
          ></i>
          <i
            className="pointer fa-solid fa-arrow-right"
            onClick={() => slider?.current?.slickNext()}
          ></i>
        </div>
      </div>

      <Slider ref={slider} {...settings}>
        <div className="pe-2">
          <img
            onClick={() => navigate("/hotDestinations")}
            src="https://images.pexels.com/photos/17446511/pexels-photo-17446511/free-photo-of-tower-of-the-church-of-el-salvador-and-santa-monica-in-valencia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="dsf"
            className="img-fluid pointer"
          />
        </div>
        <div className="pe-2">
          <img
            src="https://images.pexels.com/photos/17446511/pexels-photo-17446511/free-photo-of-tower-of-the-church-of-el-salvador-and-santa-monica-in-valencia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="dsf"
            className="img-fluid"
          />
        </div>
        <div className="pe-2">
          <img
            src="https://images.pexels.com/photos/17446511/pexels-photo-17446511/free-photo-of-tower-of-the-church-of-el-salvador-and-santa-monica-in-valencia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="dsf"
            className="img-fluid"
          />
        </div>
        <div className="pe-2">
          <img
            src="https://images.pexels.com/photos/17446511/pexels-photo-17446511/free-photo-of-tower-of-the-church-of-el-salvador-and-santa-monica-in-valencia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="dsf"
            className="img-fluid"
          />
        </div>
        <div className="pe-2">
          <img
            src="https://images.pexels.com/photos/17446511/pexels-photo-17446511/free-photo-of-tower-of-the-church-of-el-salvador-and-santa-monica-in-valencia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="dsf"
            className="img-fluid"
          />
        </div>
        <div className="">
          <img
            src="https://images.pexels.com/photos/17446511/pexels-photo-17446511/free-photo-of-tower-of-the-church-of-el-salvador-and-santa-monica-in-valencia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="dsf"
            className="img-fluid"
          />
        </div>
      </Slider>
    </div>
  );
};

export default Destinations;
