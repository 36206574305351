import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle } from "./modalUtitlities";
import "./Modals.css";
import { SmallHeadingText } from "../../components/texts/Texts";
import { PrimaryButton } from "../../components/buttons/Buttons";
import { TextField1 } from "../../components/fields/TextFields";

export default function NewPasswordModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSetPassword = () => {
    // Handle password submission logic
    // You can add your own logic here to set the new password
    console.log("New Password:", newPassword);
    console.log("Confirm Password:", confirmPassword);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <div className="relative p-4">
            <SmallHeadingText title="Enter New Password" />
            <p className="lead fs_12">Please enter your new password.</p>
            <TextField1
              label="New Password"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={handleNewPasswordChange}
              labelclassNames="mt-2"
              iconDiv={
                <div
                  className="icon_div pointer"
                  onClick={toggleNewPasswordVisibility}
                >
                  {showNewPassword ? (
                    <i className="fa-regular fa-eye-slash"></i>
                  ) : (
                    <i className="fa-regular fa-eye"></i>
                  )}
                </div>
              }
            />
            <TextField1
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              labelclassNames="mt-2"
              iconDiv={
                <div
                  className="icon_div pointer"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? (
                    <i className="fa-regular fa-eye-slash"></i>
                  ) : (
                    <i className="fa-regular fa-eye"></i>
                  )}
                </div>
              }
            />
            <PrimaryButton
              title="Set Password"
              classNames="w-100 mt-4"
              onClick={handleSetPassword}
            />
            <i
              className="fa-solid fa-xmark cross_icon_modal"
              onClick={handleClose}
            ></i>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
