export const Modalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  maxWidth: "90%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  //   p: 4,
  borderRadius: 2,
  // maxHeight: "80vh",
  // overflowY: "auto", Modalstyle_big_width
  // overflowX: "show",
};

export const Modalstyle_big_width = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  maxWidth: "90%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  //   p: 4,
  borderRadius: 2,
  // maxHeight: "80vh",
  // overflowY: "auto",
  // overflowX: "show",
};
