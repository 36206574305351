import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle } from "./modalUtitlities";
import "./Modals.css";
import { SmallHeadingText } from "../../components/texts/Texts";
import { PrimaryButton } from "../../components/buttons/Buttons";
import { TextField1 } from "../../components/fields/TextFields";

export default function OtpModal({ modalopen, setModalOpen }) {
  const handleClose = () => setModalOpen(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = () => {
    // Handle OTP verification logic
    // You can add your own logic here
    console.log("OTP:", otp);
  };

  useEffect(() => {
    let countdown;
    if (modalopen) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0 || !modalopen) {
      clearInterval(countdown);
    }

    return () => clearInterval(countdown);
  }, [modalopen, timer]);

  //   console.log("hy");

  return (
    <div>
      <Modal
        open={modalopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <div className="relative p-4">
            <SmallHeadingText title="Enter OTP" />
            <p className="lead fs_12">
              Please enter the OTP we have just sent you on your Email Address.
            </p>
            <div className="d-flex align-items-center mt-2">
              <p className="fs_11 fw_500">example@example.com</p>
              <i className="fa-solid fa-pen fs_11 ms-2 primary_txt pointer"></i>
            </div>

            <p className="fs_11 fw_700 mt-2">Enter Verification Code</p>
            <div
              style={{ gap: "10px" }}
              className="otp-container mt-2 d-flex align-items-center "
            >
              <TextField1
                type="text"
                className="otp-input"
                value={otp}
                onChange={handleOtpChange}
                maxLength={1}
              />
              <TextField1
                type="text"
                className="otp-input"
                value={otp}
                onChange={handleOtpChange}
                maxLength={1}
              />
              <TextField1
                type="text"
                className="otp-input"
                value={otp}
                onChange={handleOtpChange}
                maxLength={1}
              />
              <TextField1
                type="text"
                className="otp-input"
                value={otp}
                onChange={handleOtpChange}
                maxLength={1}
              />
            </div>
            <div className="d-flex align-items-center mt-2 fs_11 primary_txt justify-content-center mt-3 ">
              <i className="fa-solid fa-clock"></i>
              <span className="ms-1">{timer} seconds</span>
            </div>
            <PrimaryButton
              title="Submit"
              classNames="w-100 mt-4"
              onClick={handleSubmit}
            />
            <i
              className="fa-solid fa-xmark cross_icon_modal"
              onClick={handleClose}
            ></i>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
