import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./hotDestination.css";
import { SmallBoldText } from "../../components/texts/Texts";
import { flightsData } from "../StaticData";

const HotDestinations = () => {
  return (
    <>
      <div className="container mt-3">
        <div className="row ">
          <Header />
        </div>
      </div>
      <div className="cont_bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-5 mx-auto">
              <div className="hot_destination_container border_style_1 shadow-sm br_4 p-2 mt-5">
                <SmallBoldText
                  title="Hot Destinations"
                  classNames="primary_txt"
                />
                <p className="fs_12">France</p>
                {flightsData.map((flight, index) => (
                  <div key={index} className="border_style_1 p-2 mt-3 br_4">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <SmallBoldText
                          title={flight.airline}
                          classNames="fs_11"
                        />
                        <p className="fs_10 dim_txt ms-2">
                          {flight.flightNumber}
                        </p>
                      </div>{" "}
                      <SmallBoldText title={flight.price} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HotDestinations;
