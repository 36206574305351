import React, { useState } from "react";
import { MediumHeadingText } from "../../../components/texts/Texts";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@mui/styles";
import { active_bids_list } from "../../StaticData";
import TripWithFriends from "./TripWithFriends";
import "./myBids.css";

const useStyles = makeStyles(() => ({
  // tabContext: {
  //   borderBottom: `2px solid red`,
  // },
  tabList: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTab-root": {
      color: "#000",
      border: "2px solid transparent",
      borderBottomColor: "transparent",
      padding: "6px !important ",
      cursor: "pointer",
      transition: "border-bottom-color 0.3s",
      fontSize: "13px !important",
      textTransform: "none",
      fontWeight: "700 !important",
    },
    "& .Mui-selected": {
      borderBottomColor: "red",
      color: "#000 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "red",
    },
  },
}));

const MyBids = () => {
  const [value, setValue] = React.useState("1");
  const [trips, setTrips] = useState("");
  const [bidsGraph, setBidsGraph] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log(viewDetail  );
  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className={`${
              bidsGraph === true ? "col-sm-12" : "col-sm-6 border_style_1"
            }  p-3 br_4`}
          >
            {(bidsGraph === false || viewDetail === true) && (
              <MediumHeadingText
                classNames={trips === "" ? "" : "mb-3"}
                title={
                  trips === ""
                    ? "My Bids"
                    : viewDetail === true
                    ? "View Detail"
                    : "Trips with Friends"
                }
              />
            )}

            {trips === "" ? (
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value} className={classes.tabContext}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      className={`${classes.tabList} mt-2`} // Use the classes from useStyles
                    >
                      <Tab label="Active" value="1" />
                      <Tab label="Failed" value="2" />
                    </TabList>
                  </Box>
                  <div className="p-0 pt-2 "></div>
                  <TabPanel value="1" className="p-0 mt-2 ">
                    {active_bids_list.map((item, index) => (
                      <div
                        onClick={() => setTrips("active_trips_with_friends")}
                        key={index}
                        className="border_style_1 p-2 br_4 mt-1 pointer"
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="fs_11 fw_500">{item.title}</p>
                          <p className="fs_11 fw_700">{item.price}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="fs_11 mt-1">{item.bids}</p>
                          <p className="fs_11 mt-1 primary_txt">
                            {item.discount}
                          </p>
                        </div>
                      </div>
                    ))}
                  </TabPanel>
                  <TabPanel value="2" className="p-0 mt-2">
                    {active_bids_list.map((item, index) => (
                      <div
                        onClick={() => setTrips("cancel_trips_with_friends")}
                        key={index}
                        className="border_style_1 p-2 br_4 mt-1 pointer"
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="fs_11 fw_500">{item.title}</p>
                          <p className="fs_11 fw_700">{item.price}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="fs_11 mt-1">{item.bids}</p>

                          <p className="fs_11 mt-1 react_col reactivate_bg px-2 fw_500">
                            Reactivate
                          </p>
                        </div>
                      </div>
                    ))}
                  </TabPanel>
                </TabContext>
              </Box>
            ) : (
              <>
                <i
                  onClick={() => {
                    setTrips("");
                    setBidsGraph(false);
                    setViewDetail(false);
                  }}
                  className="fa-solid fa-backward pointer"
                ></i>
                <TripWithFriends
                  trips={trips}
                  setBidsGraph={setBidsGraph}
                  bidsGraph={bidsGraph}
                  viewDetail={viewDetail}
                  setViewDetail={setViewDetail}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyBids;
