import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle } from "./modalUtitlities";
import "./Modals.css";
import { SmallBoldText, SmallHeadingText } from "../../components/texts/Texts";
import { TextField1 } from "../../components/fields/TextFields";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/Buttons";
import google from "../../assets/images/google.png";
import facebook from "../../assets/images/facebook.png";
import apple from "../../assets/images/apple.png";
import ForgotPasswordModal from "./ForgotPasswordModal";

export default function LoginModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <div className="relative p-4">
            <SmallHeadingText title="Login" />
            <p className="lead fs_12 ">Welcome Back , Login to Your Account</p>
            <TextField1 label="Email" labelclassNames="mt-2" />
            <TextField1
              label="Password"
              type={showPassword === true ? "text" : "password"}
              labelclassNames="mt-2"
              iconDiv={
                <div
                  className="icon_div pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword === true ? (
                    <i className="fa-regular fa-eye-slash"></i>
                  ) : (
                    <i className="fa-sharp fa-regular fa-eye"></i>
                  )}
                </div>
              }
            />{" "}
            <SmallBoldText
              onClick={() => {
                setForgotPassword(true);
                handleClose();
              }}
              title="Forgot Password"
              classNames="primary_txt text-end mt-3 pointer"
            />
            <PrimaryButton title="login" classNames="w-100 mt-4" />
            <SecondaryButton
              title="Continue as Guest"
              classNames="w-100 mt-2"
            />
            <div className="d-flex align-items-center mt-3">
              <div className="border_footer_continue"></div>
              <p className="lead fs_12 mx-2">or continue with</p>
              <div className="border_footer_continue"></div>
            </div>
            <div
              style={{ gap: "10px" }}
              className="d-flex align-items-center justify-content-center mt-3"
            >
              <div className="social_ic_div">
                <img src={google} alt="socialLogo" style={{ width: "20px" }} />
              </div>{" "}
              <div className="social_ic_div">
                <img
                  src={facebook}
                  alt="socialLogo"
                  style={{ width: "20px" }}
                />
              </div>{" "}
              <div className="social_ic_div">
                <img src={apple} alt="socialLogo" style={{ width: "20px" }} />
              </div>
            </div>
            <div
              style={{ gap: "10px" }}
              className="d-flex align-items-center justify-content-center mt-3"
            >
              {" "}
              <p className="lead fs_12 ">Dont't have an Account?</p>
              <SmallBoldText title="Sign Up" classNames="primary_txt" />
            </div>
            <i
              className="fa-solid fa-xmark cross_icon_modal"
              onClick={handleClose}
            ></i>
          </div>
        </Box>
      </Modal>
      <ForgotPasswordModal open={forgotPassword} setOpen={setForgotPassword} />
    </div>
  );
}
