import React from "react";
import { MediumHeadingText } from "../../components/texts/Texts";
import Slider from "react-slick";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Testimonials = () => {
  const slider = React.useRef(null);
  return (
    <>
      <div
        style={{
          background:
            "linear-gradient(90deg, rgba(20,146,220,1) 0%, rgba(170,104,241,1) 100%)",
        }}
        className="container-fluid p-3 mt-5 pb-5"
      >
        <div className="container">
          <div className="row pt-4">
            <div className="col-sm-3">
              <p className="text-white lead fs_12 opac_07 mb-3">TESTIMONIALS</p>
              <MediumHeadingText
                title="Some Friends "
                classNames="text-white"
              />
              <MediumHeadingText title="We've Made" classNames="text-white" />
              <MediumHeadingText
                title=" in The Process."
                classNames="text-white"
              />
              <div className="d-flex align-items-center mt-5">
                <p className="fs_12 text-white fw_700 ">Read More</p>
                <i className="fa-solid fa-arrow-right-long text-white ms-2"></i>
              </div>
            </div>
            <div className="col-sm-9">
              <Slider ref={slider} {...settings}>
                <div style={{ padding: "0px 10px !important" }}>
                  <div style={{ padding: "0px 10px !important" }}>
                    <p
                      style={{ lineHeight: "30px" }}
                      className="fs_12 dimBorder_texxt lead font-italic p-2"
                    >
                      “I recently discovered this fight booking website that
                      offers a unique feature: a bidding option. It's a
                      game-changer! I had a specific budget in mind for my
                      upcoming trip, and the bidding option allowed me to name
                      my price for the fight I wanted.”
                    </p>
                  </div>
                </div>
                <div style={{ padding: "0px 10px !important" }}>
                  <p
                    style={{ lineHeight: "30px" }}
                    className="fs_12 dimBorder_texxt lead font-italic p-2"
                  >
                    “I recently discovered this fight booking website that
                    offers a unique feature: a bidding option. It's a
                    game-changer! I had a specific budget in mind for my
                    upcoming trip, and the bidding option allowed me to name my
                    price for the fight I wanted.”
                  </p>
                </div>
                <div style={{ padding: "0px 10px !important" }}>
                  <p
                    style={{ lineHeight: "30px" }}
                    className="fs_12 dimBorder_texxt lead font-italic p-2"
                  >
                    “I recently discovered this fight booking website that
                    offers a unique feature: a bidding option. It's a
                    game-changer! I had a specific budget in mind for my
                    upcoming trip, and the bidding option allowed me to name my
                    price for the fight I wanted.”
                  </p>
                </div>
              </Slider>
              <div
                style={{ gap: "30px" }}
                className="d-flex align-items-center justify-content-end mt-3"
              >
                <i
                  className="pointer fa-solid fa-arrow-left text-white"
                  onClick={() => slider?.current?.slickPrev()}
                ></i>
                <i
                  className="pointer fa-solid fa-arrow-right text-white"
                  onClick={() => slider?.current?.slickNext()}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
