import React from "react";
import Header from "../../components/header/Header";
import TripSelector from "../home/TripSelector";
import Footer from "../../components/footer/Footer";
import { SmallHeadingText, SmallText } from "../../components/texts/Texts";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./FlightList.css";
import { FlightData } from "../StaticData";
import air_icon from "../../assets/images/air_icon.png";
import { Button } from "@mui/material";

const FlightList = () => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="container">
        <div className="row mt-3 relative">
          <Header />
        </div>
      </div>
      <div
        style={{
          background:
            "linear-gradient(90deg, rgba(20,146,220,1) 0%, rgba(170,104,241,1) 100%)",
        }}
        className="container-fluid mt-3"
      >
        <div className="container py-3">
          <TripSelector flightList={true} />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-11 mx-auto mt-5">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <SmallHeadingText title="05 Flights from Sydney to Bengaluru" />
              <div
                style={{ gap: "10px" }}
                className="d-flex align-items-center"
              >
                <p className={`fs_12 fw_500 ellipsis`}>Sort By</p>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">Cheapest</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={age}
                    label="Cheapest"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="table-responsive mt-3">
              <table className="table flights-table">
                <tbody>
                  {FlightData.map((flight, index) => (
                    <React.Fragment key={index}>
                      <tr className="main_table_box border_style_1 py-3 ">
                        <td className="py-3">
                          <div className="d-flex align-items-center">
                            <i className="fa-sharp fa-solid fa-plane-departure dimBorder_texxt me-3 fs_12"></i>
                            <div>
                              <SmallText
                                title={flight.FlightName}
                                classNames={`${"fw_700"}`}
                              />
                              <p className="fs_12 dim_txt">
                                {flight.flightNumber}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{ gap: "20px" }}
                            className="mt-3 d-flex align-items-center"
                          >
                            <i className="fa-solid fa-briefcase"></i>
                            <p className="fs_12 fw_500 ">{`Economy- ${flight.economy_seat} seats`}</p>
                          </div>
                        </td>
                        <td className="py-3">
                          <SmallText
                            title={flight.departTime}
                            classNames={`${"fw_700"}`}
                          />
                          <p className="fs_12 dim_txt">{flight.from}</p>
                        </td>

                        <td className="py-3">
                          <img src={air_icon} alt="air_icon" />
                          <p className="fs_12 dimBorder_texxt">
                            {flight.timing}
                          </p>
                        </td>
                        <td className="py-3">
                          <SmallText
                            title={flight.arrivalTime}
                            classNames={`${"fw_700"}`}
                          />
                          <p className="fs_12 dim_txt">{flight.to}</p>
                        </td>
                        <td className="py-3">
                          <p className="fs_12 fw_500">AVG Per Person</p>
                          <SmallHeadingText
                            classNames="primary_txt"
                            title={`$ ${flight.minPrice}`}
                          />
                          <p className="fs_12 fw_700">{flight.maxPrice}</p>
                        </td>
                        <td className="py-3">
                          <Button
                            className="fw_700"
                            sx={{ textTransform: "none", fontWeight: 700 }}
                            variant="outlined"
                            size="small"
                          >
                            View Details
                          </Button>
                        </td>
                      </tr>
                      <tr style={{ height: "20px" }}>
                        <td colSpan="9"></td>
                      </tr>
                    </React.Fragment>
                  ))}

                  <tr style={{ height: "20px" }}>
                    <td colSpan="9"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FlightList;
