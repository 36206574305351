import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { MediumHeadingText } from "../../components/texts/Texts";
import add_card from "../../assets/images/add_card.png";
import Slider from "react-slick";
import "./Paymen.css";
import mastercard from "../../assets/images/mastercard.png";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Payment = () => {
  return (
    <>
      {" "}
      <div className="container">
        <div className="row mt-3 relative">
          <Header />
        </div>
        <div
          style={{ background: "#fff" }}
          className="row border_style_1 mt-4 br_4 p-3"
        >
          <MediumHeadingText title="Payment" />
          <p style={{ opacity: 0.7 }} className="fs_12 dim_txt fw_500 mt-1 ">
            Traveller Ticket Payment
          </p>
          <div className="col-sm-1 d-flex align-items-center justify-content-center mt-3">
            <div
              style={{ flexGrow: 1 }}
              className="border_style_1 p-2 br_8 d-flex align-items-center justify-content-center"
            >
              <img src={add_card} alt="add_card" />
            </div>
          </div>
          <div className="col-sm-11 mt-3">
            <Slider {...settings}>
              <div className="pe-2">
                <div className="card_holder_div shadow-sm br_8 text-white p-3   ">
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={mastercard} alt="astercard" width="30px" />
                    <i class="fa-solid fa-trash"></i>
                  </div>
                  <p className="fs_14 mt-3">1234********789</p>
                  <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                    <div>
                      {" "}
                      <p style={{ opacity: 0.7 }} className="fs_12">
                        CardHolder{" "}
                      </p>
                      <p className="fs_12">Alice Buttler</p>
                    </div>{" "}
                    <div>
                      {" "}
                      <p style={{ opacity: 0.7 }} className="fs_12">
                        Month/Year{" "}
                      </p>
                      <p className="fs_12">01/24</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pe-2">
                <div className="card_holder_div shadow-sm br_8 text-white p-3   ">
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={mastercard} alt="astercard" width="30px" />
                    <i class="fa-solid fa-trash"></i>
                  </div>
                  <p className="fs_14 mt-3">1234********789</p>
                  <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                    <div>
                      {" "}
                      <p style={{ opacity: 0.7 }} className="fs_12">
                        CardHolder{" "}
                      </p>
                      <p className="fs_12">Alice Buttler</p>
                    </div>{" "}
                    <div>
                      {" "}
                      <p style={{ opacity: 0.7 }} className="fs_12">
                        Month/Year{" "}
                      </p>
                      <p className="fs_12">01/24</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pe-2">
                <div className="card_holder_div shadow-sm br_8 text-white p-3   ">
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={mastercard} alt="astercard" width="30px" />
                    <i class="fa-solid fa-trash"></i>
                  </div>
                  <p className="fs_14 mt-3">1234********789</p>
                  <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                    <div>
                      {" "}
                      <p style={{ opacity: 0.7 }} className="fs_12">
                        CardHolder{" "}
                      </p>
                      <p className="fs_12">Alice Buttler</p>
                    </div>{" "}
                    <div>
                      {" "}
                      <p style={{ opacity: 0.7 }} className="fs_12">
                        Month/Year{" "}
                      </p>
                      <p className="fs_12">01/24</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pe-2">
                <div className="card_holder_div shadow-sm br_8 text-white p-3   ">
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={mastercard} alt="astercard" width="30px" />
                    <i class="fa-solid fa-trash"></i>
                  </div>
                  <p className="fs_14 mt-3">1234********789</p>
                  <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                    <div>
                      {" "}
                      <p style={{ opacity: 0.7 }} className="fs_12">
                        CardHolder{" "}
                      </p>
                      <p className="fs_12">Alice Buttler</p>
                    </div>{" "}
                    <div>
                      {" "}
                      <p style={{ opacity: 0.7 }} className="fs_12">
                        Month/Year{" "}
                      </p>
                      <p className="fs_12">01/24</p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Payment;
