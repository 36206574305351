import { styled } from "@mui/material/styles";
import { SmallBoldText } from "../texts/Texts";
import { Button } from "@mui/material";

const Primary = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: `var(--primary)`,
  "&:hover": {
    backgroundColor: `var(--primary)`,
  },
  textTransform: "none ",
  paddingLeft: " 20px",
  paddingRight: " 20px",
}));

const PrimaryGreen = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: `var(--green_active)`,
  "&:hover": {
    backgroundColor: `var(--green_active)`,
  },
  textTransform: "none ",
  paddingLeft: " 20px",
  paddingRight: " 20px",
}));

const Secondary = styled(Button)(({ color }) => ({
  color: "var(--primary)",
  border: `2px solid ${"var(--primary)"}`,
  "&:hover": {
    border: `2px solid ${"var(--primary)"}`,
  },
  textTransform: "none ",
  paddingLeft: "20px",
  paddingRight: "20px",
}));

const SecondaryLight = styled(Button)(({ color }) => ({
  color: "#fff",
  border: `2px solid ${"#fff"}`,
  "&:hover": {
    border: `2px solid ${"#fff"}`,
  },
  textTransform: "none ",
  paddingLeft: "20px",
  paddingRight: "20px",
}));

const Third = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: `var(--third)`,
  "&:hover": {
    backgroundColor: `var(--third)`,
  },
  textTransform: "none ",
  paddingLeft: " 20px",
  paddingRight: " 20px",
}));

const DimButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: `var(--dim)`,
  "&:hover": {
    backgroundColor: `var(--dim)`,
  },
  textTransform: "none ",
  paddingLeft: " 20px",
  paddingRight: " 20px",
}));

export const PrimaryButton = ({ title, classNames, endIcon, onClick }) => {
  return (
    <Primary
      onClick={onClick && onClick}
      endIcon={endIcon && endIcon}
      className={`${classNames && classNames} `}
    >
      <SmallBoldText title={title} />
    </Primary>
  );
};

export const PrimaryGreenButton = ({ title, classNames, endIcon, onClick }) => {
  return (
    <PrimaryGreen
      onClick={onClick && onClick}
      endIcon={endIcon && endIcon}
      className={`${classNames && classNames} `}
    >
      <SmallBoldText title={title} />
    </PrimaryGreen>
  );
};

export const SecondaryButton = ({
  title,
  classNames,
  endIcon,
  startIcon,
  onClick,
  color,
}) => {
  const isWhiteColor = color === "white";
  return (
    <>
      {isWhiteColor ? (
        <SecondaryLight
          variant="outlined"
          onClick={onClick && onClick}
          endIcon={endIcon && endIcon}
          startIcon={startIcon && startIcon}
          className={`${classNames && classNames} `}
        >
          <SmallBoldText title={title} />
        </SecondaryLight>
      ) : (
        <Secondary
          variant="outlined"
          onClick={onClick && onClick}
          endIcon={endIcon && endIcon}
          startIcon={startIcon && startIcon}
          className={`${classNames && classNames} `}
        >
          <SmallBoldText title={title} />
        </Secondary>
      )}
    </>
  );
};

export const ThirdButton = ({ title, classNames, endIcon, onClick }) => {
  return (
    <Third
      onClick={onClick && onClick}
      endIcon={endIcon && endIcon}
      className={`${classNames && classNames} `}
    >
      <SmallBoldText title={title} />
    </Third>
  );
};

export const DimsButton = ({ title, classNames, endIcon, onClick }) => {
  return (
    <DimButton
      onClick={onClick && onClick}
      endIcon={endIcon && endIcon}
      className={`${classNames && classNames} `}
    >
      <SmallBoldText title={title} />
    </DimButton>
  );
};
