import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle } from "./modalUtitlities";
import { SmallHeadingText } from "../../components/texts/Texts";
import { PrimaryButton } from "../../components/buttons/Buttons";
import { Checkbox } from "@mui/material";

export default function FlyingModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [adultCount, setAdultCount] = useState(1);
  const [childrenCount, setChildrenCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const [cabinClass, setCabinClass] = useState("economy");

  const handleAdultMinus = () => {
    setAdultCount((prevCount) => Math.max(prevCount - 1, 0));
  };

  const handleAdultPlus = () => {
    setAdultCount((prevCount) => prevCount + 1);
  };

  const handleChildrenMinus = () => {
    setChildrenCount((prevCount) => Math.max(prevCount - 1, 0));
  };

  const handleChildrenPlus = () => {
    setChildrenCount((prevCount) => prevCount + 1);
  };

  const handleInfantMinus = () => {
    setInfantCount((prevCount) => Math.max(prevCount - 1, 0));
  };

  const handleInfantPlus = () => {
    handleCabinClassChange();
    setInfantCount((prevCount) => prevCount + 1);
  };

  const handleCabinClassChange = (event) => {
    setCabinClass(event.target.value);
  };

  const handleApply = () => {
    // Handle apply button logic
    // You can add your own logic here to apply the selected options
    console.log("Adult Count:", adultCount);
    console.log("Children Count:", childrenCount);
    console.log("Infant Count:", infantCount);
    console.log("Cabin Class:", cabinClass);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Modalstyle}>
        <div className="relative p-4">
          <SmallHeadingText title="Who is Flying" />
          <p className="fs_12 fw_500 ">Travellers</p>
          <div className="travellers_container mt-2">
            <div className="traveller_type mt-2 d-flex align-items-center justify-content-between">
              <div
                style={{ gap: "10px" }}
                className="d-flex align-items-center"
              >
                <i className="fa-solid fa-person"></i>
                <p className="lead fs_12">Adult (12y+)</p>
              </div>

              <div
                style={{ gap: "15px" }}
                className="traveller_count d-flex align-items-center "
              >
                <div
                  className="traveller_button border_style_1 pointer fs_11"
                  onClick={handleAdultMinus}
                >
                  -
                </div>
                <div className="traveller_number">{adultCount}</div>
                <div
                  className="traveller_button border_style_1 pointer fs_11"
                  onClick={handleAdultPlus}
                >
                  +
                </div>
              </div>
            </div>
            <div className="traveller_type mt-2 d-flex align-items-center justify-content-between">
              <div
                style={{ gap: "10px" }}
                className="d-flex align-items-center"
              >
                <i className="fa-solid fa-child"></i>
                <p className="lead fs_12">Children (2y-12y)</p>
              </div>

              <div
                style={{ gap: "15px" }}
                className="traveller_count d-flex align-items-center "
              >
                <div
                  className="traveller_button border_style_1 pointer fs_11"
                  onClick={handleChildrenMinus}
                >
                  -
                </div>
                <div className="traveller_number">{childrenCount}</div>
                <div
                  className="traveller_button border_style_1 pointer fs_11"
                  onClick={handleChildrenPlus}
                >
                  +
                </div>
              </div>
            </div>
            <div className="traveller_type mt-2 d-flex align-items-center justify-content-between">
              <div
                style={{ gap: "10px" }}
                className="d-flex align-items-center"
              >
                <i className="fa-solid fa-baby"></i>
                <p className="lead fs_12">Infant (below 2y)</p>
              </div>

              <div
                style={{ gap: "15px" }}
                className="traveller_count d-flex align-items-center "
              >
                <div
                  className="traveller_button border_style_1 pointer fs_11"
                  onClick={handleInfantMinus}
                >
                  -
                </div>
                <div className="traveller_number">{infantCount}</div>
                <div
                  className="traveller_button border_style_1 pointer fs_11"
                  onClick={handleInfantPlus}
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <p className="fs_12 fw_500 mt-3 mb-1 ">Cabin Class</p>
          <div
            style={{ marginLeft: "-3px" }}
            className="cabin_class_container d-flex align-items-center   "
          >
            <Checkbox
              className="me-2"
              {...label}
              defaultChecked
              sx={{
                width: "10px",
                color: "var(--primary)",
                "&.Mui-checked": {
                  color: "var(--primary)",
                },
                "& .MuiSvgIcon-root": { fontSize: 14 },
              }}
            />

            <p className="fs_12 fw_500  ">Economy</p>
          </div>
          <div
            style={{ marginLeft: "-3px" }}
            className="cabin_class_container d-flex align-items-center   "
          >
            <Checkbox
              className="me-2"
              {...label}
              defaultChecked
              sx={{
                width: "10px",
                color: "var(--primary)",
                "&.Mui-checked": {
                  color: "var(--primary)",
                },
                "& .MuiSvgIcon-root": { fontSize: 14 },
              }}
            />

            <p className="fs_12 fw_500  ">Premium Economy</p>
          </div>{" "}
          <div
            style={{ marginLeft: "-3px" }}
            className="cabin_class_container d-flex align-items-center   "
          >
            <Checkbox
              className="me-2"
              {...label}
              defaultChecked
              sx={{
                width: "10px",
                color: "var(--primary)",
                "&.Mui-checked": {
                  color: "var(--primary)",
                },
                "& .MuiSvgIcon-root": { fontSize: 14 },
              }}
            />

            <p className="fs_12 fw_500  ">Buisness</p>
          </div>{" "}
          <div
            style={{ marginLeft: "-3px" }}
            className="cabin_class_container d-flex align-items-center   "
          >
            <Checkbox
              className="me-2"
              {...label}
              defaultChecked
              sx={{
                width: "10px",
                color: "var(--primary)",
                "&.Mui-checked": {
                  color: "var(--primary)",
                },
                "& .MuiSvgIcon-root": { fontSize: 14 },
              }}
            />

            <p className="fs_12 fw_500  ">First Class</p>
          </div>
          <PrimaryButton
            title="Apply"
            classNames="w-100 mt-4"
            onClick={handleApply}
          />
          <i
            className="fa-solid fa-xmark cross_icon_modal"
            onClick={handleClose}
          ></i>
        </div>
      </Box>
    </Modal>
  );
}
