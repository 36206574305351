import React, { useState } from "react";
import { SmallText } from "../../components/texts/Texts";
import { Grid, Radio } from "@mui/material";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/Buttons";
import FlyingModal from "../modals/FLyingModal";

const tabs = [
  { title: "One Way" },
  { title: " Round Trip" },
  { title: "Multi City" },
];

const TripSelector = ({ flightList }) => {
  const [activeTab, setActiveTab] = useState("One Way");
  const [flyingModal, setFlyingModal] = useState(false);

  // console.log(flightList);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className=" row ">
        <div className="col-sm-12 ">
          <div className="way_selctor d-flex align-items-center ">
            {tabs.map((data, i) =>
              flightList === true ? (
                <FlightTabs
                  key={i}
                  activeTab={activeTab}
                  data={data}
                  handleTabClick={handleTabClick}
                />
              ) : (
                <HomeTabs
                  key={i}
                  activeTab={activeTab}
                  data={data}
                  handleTabClick={handleTabClick}
                />
              )
            )}
          </div>
          <div
            style={{ background: flightList === true && "none" }}
            className="trip_details_box shadow-sm "
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <div className="border_style_1 trip_box   relative ps-4 d-flex align-items-center">
                  <div className="o_auto">
                    <div className="d-flex align-items-center">
                      <i className="fa-sharp fa-solid fa-plane-departure dimBorder_texxt me-4 fs_12"></i>
                      <div>
                        <p className="fs_12  dimBorder_texxt">From</p>
                        <h6
                          className={`${flightList === true && "text-white"}`}
                        >
                          SYD
                        </h6>
                        <p
                          className={`${
                            flightList === true ? "text-white" : "dim_txt"
                          } fs_12   fw_500  ellipsis `}
                        >
                          KingFord South Australia
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="arrow_for_middle_two_row border_style_1 d-none d-sm-block">
                    <i className="fa-solid fa-arrow-right-arrow-left fs_12 "></i>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <div className="border_style_1 trip_box o_auto  relative ps-4 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="fa-sharp fa-solid fa-plane-arrival dimBorder_texxt me-4 fs_12"></i>
                    <div>
                      <p className="fs_12  dimBorder_texxt">To</p>
                      <h6 className={`${flightList === true && "text-white"}`}>
                        BLR
                      </h6>
                      <p
                        className={`${
                          flightList === true ? "text-white" : "dim_txt"
                        } fs_12   fw_500  ellipsis `}
                      >
                        Bengaluru International Airport
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.5}>
                <div className="border_style_1 trip_box o_auto  relative ps-4 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <i className=" fa-solid fa-calendar-days dimBorder_texxt me-4 fs_12"></i>
                    <div>
                      <div className="d-flex align-items-center">
                        <p className="fs_12  dimBorder_texxt">Depart</p>
                        <i className="fa-solid fa-chevron-down ms-2 fs_12 primary_txt fw_700"></i>
                      </div>{" "}
                      <div className="d-flex align-items-center">
                        <h6
                          className={`${flightList === true && "text-white"}`}
                        >
                          09
                        </h6>
                        <p
                          className={`${
                            flightList === true ? "text-white" : "dim_txt"
                          } ms-2 fs_12   fw_500  ellipsis `}
                        >
                          July 23
                        </p>
                      </div>
                      <p
                        className={`${
                          flightList === true ? "text-white" : "dim_txt"
                        } fs_12   fw_500  ellipsis `}
                      >
                        Sunday
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>

              {activeTab === "One Way" ? (
                <Grid item xs={12} sm={3} md={3} lg={1.5}>
                  <div className="border_style_1 trip_box o_auto  relative ps-4 d-flex align-items-center">
                    {" "}
                    <div className="d-flex align-items-center">
                      <i className=" fa-solid fa-calendar-days dimBorder_texxt me-4 fs_12"></i>
                      <div>
                        <div className="d-flex align-items-center">
                          <p className="fs_12  dimBorder_texxt">Return</p>
                          <i className="fa-solid fa-chevron-down ms-2 fs_12 primary_txt fw_700"></i>
                        </div>{" "}
                        <p
                          className={`${
                            flightList === true ? "text-white" : "dim_txt"
                          } fs_12   fw_500  ellipsis `}
                        >
                          Tap to add a return date
                        </p>
                      </div>{" "}
                      <i className="fa-solid fa-xmark dim_txt fs_12 icon_cancel_return_date "></i>
                    </div>
                  </div>
                </Grid>
              ) : activeTab === "Round Trip" ? (
                <Grid item xs={12} sm={3} md={3} lg={1.5}>
                  <div className="border_style_1 trip_box o_auto  relative ps-4 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <i className=" fa-solid fa-calendar-days dimBorder_texxt me-4 fs_12"></i>
                      <div>
                        <div className="d-flex align-items-center">
                          <p className="fs_12  dimBorder_texxt">Return</p>
                          <i className="fa-solid fa-chevron-down ms-2 fs_12 primary_txt fw_700"></i>
                        </div>

                        <div className="d-flex align-items-center">
                          <h6
                            className={`${flightList === true && "text-white"}`}
                          >
                            11
                          </h6>
                          <p
                            className={`${
                              flightList === true ? "text-white" : "dim_txt"
                            } ms-2 fs_12   fw_500  ellipsis `}
                          >
                            July 23
                          </p>
                        </div>
                        <p
                          className={`${
                            flightList === true ? "text-white" : "dim_txt"
                          } fs_12   fw_500  ellipsis `}
                        >
                          Tuesday
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12} sm={6} md={3}>
                <div
                  onClick={() => setFlyingModal(true)}
                  className="border_style_1 trip_box o_auto  relative ps-4 d-flex align-items-center pointer"
                >
                  <div className="d-flex align-items-center">
                    <i className="fa-solid fa-people-group  dimBorder_texxt me-4 fs_12"></i>
                    <div>
                      <div className="d-flex align-items-center">
                        <p className="fs_12  dimBorder_texxt">
                          Class and Treavllers
                        </p>
                        <i className="fa-solid fa-chevron-down ms-2 fs_12 primary_txt fw_700"></i>
                      </div>{" "}
                      <div
                        style={{ gap: "10px" }}
                        className="d-flex align-items-center"
                      >
                        <div className="d-flex align-items-center">
                          <h6
                            className={`${flightList === true && "text-white"}`}
                          >
                            2
                          </h6>
                          <p
                            className={`${
                              flightList === true ? "text-white" : "dim_txt"
                            } ms-2 fs_12   fw_500  ellipsis `}
                          >
                            Adults,
                          </p>
                        </div>{" "}
                        <div className="d-flex align-items-center">
                          <h6
                            className={`${flightList === true && "text-white"}`}
                          >
                            1
                          </h6>
                          <p
                            className={`${
                              flightList === true ? "text-white" : "dim_txt"
                            } ms-2 fs_12   fw_500  ellipsis `}
                          >
                            Kids
                          </p>
                        </div>
                      </div>
                      <p
                        className={`${
                          flightList === true ? "text-white" : "dim_txt"
                        } fs_12   fw_500  ellipsis `}
                      >
                        Sunday
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>

              {activeTab === "Multi City" && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="border_style_1 trip_box   relative ps-4 d-flex align-items-center">
                      <div className="o_auto">
                        <div className="d-flex align-items-center">
                          <i className="fa-sharp fa-solid fa-plane-arrival dimBorder_texxt me-4 fs_12"></i>
                          <div>
                            <p className="fs_12  dimBorder_texxt">To</p>
                            <h6
                              className={`${
                                flightList === true && "text-white"
                              }`}
                            >
                              BLR
                            </h6>
                            <p
                              className={`${
                                flightList === true ? "text-white" : "dim_txt"
                              } fs_12   fw_500  ellipsis `}
                            >
                              Bengaluru International Airport
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="arrow_for_middle_two_row border_style_1 d-none d-sm-block">
                        <i className="fa-solid fa-arrow-right-arrow-left fs_12 "></i>
                      </div>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={12} sm={6} md={3}>
                    <div className="border_style_1 trip_box o_auto  relative ps-4 d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <i className="fa-sharp fa-solid fa-plane-arrival dimBorder_texxt me-4 fs_12"></i>
                        <div>
                          <p className="fs_12  dimBorder_texxt">To</p>
                          <h6
                            className={`${flightList === true && "text-white"}`}
                          >
                            PNQ
                          </h6>
                          <p
                            className={`${
                              flightList === true ? "text-white" : "dim_txt"
                            } fs_12   fw_500  ellipsis `}
                          >
                            Pune Airport
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={1.5}>
                    <div className="border_style_1 trip_box o_auto  relative ps-4 d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <i className=" fa-solid fa-calendar-days dimBorder_texxt me-4 fs_12"></i>
                        <div>
                          <div className="d-flex align-items-center">
                            <p className="fs_12  dimBorder_texxt">Return</p>
                            <i className="fa-solid fa-chevron-down ms-2 fs_12 primary_txt fw_700"></i>
                          </div>

                          <div className="d-flex align-items-center">
                            <h6
                              className={`${
                                flightList === true && "text-white"
                              }`}
                            >
                              11
                            </h6>
                            <p
                              className={`${
                                flightList === true ? "text-white" : "dim_txt"
                              } ms-2 fs_12   fw_500  ellipsis `}
                            >
                              July 23
                            </p>
                          </div>
                          <p
                            className={`${
                              flightList === true ? "text-white" : "dim_txt"
                            } fs_12   fw_500  ellipsis `}
                          >
                            Tuesday
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
            <div
              style={{ gap: "20px" }}
              className="mt-3 d-flex align-items-center justify-content-between flex-wrap "
            >
              <div className=" d-flex align-items-center ">
                <i className="fa-solid fa-square-check fs_12 primary_txt fw_700"></i>
                <p className="fs_12 ms-2"> Show Direct Flights only</p>
              </div>
              <div
                style={{ gap: "10px" }}
                className="d-flex align-items-center flex-wrap"
              >
                {activeTab === "Multi City" && (
                  <SecondaryButton
                    title="Add Another City"
                    startIcon={<i className="fa-solid fa-circle-plus"></i>}
                  />
                )}

                <PrimaryButton
                  title="Search Flights"
                  endIcon={<i className="fa-solid fa-arrow-right-long"></i>}
                />
              </div>
            </div>
          </div>{" "}
          <div></div>
        </div>
        <FlyingModal open={flyingModal} setOpen={setFlyingModal} />
      </div>
    </>
  );
};

const HomeTabs = ({ activeTab, data, handleTabClick }) => {
  return (
    <div
      className={`dim_bg text-white tab_selector pointer ${
        activeTab === data.title ? "bg_primary" : ""
      }`}
      onClick={() => handleTabClick(data.title)}
    >
      <SmallText title={data.title} />
    </div>
  );
};
const FlightTabs = ({ activeTab, data, handleTabClick }) => {
  return (
    <div
      className={` text-white  pointer d-flex align-items-center ${
        activeTab === data.title ? "fs_700" : ""
      }`}
      onClick={() => handleTabClick(data.title)}
    >
      <Radio
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: 18,
          },
          color: `#fff`,
          "&.Mui-checked": {
            color: `var(--primary)`,
          },
        }}
        checked={activeTab === data.title}
        name="radio-buttons"
        inputProps={{ "aria-label": "A" }}
      />
      <SmallText
        title={data.title}
        classNames={`${activeTab === data.title ? "fw_700" : ""}`}
      />
    </div>
  );
};

export default TripSelector;
