import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: "null",
};

export const userSice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
    },
  },
});

export const { logout } = userSice.actions;

export default userSice.reducer;
