import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle_big_width } from "./modalUtitlities";
import "./Modals.css";
import { SmallHeadingText, SmallText } from "../../components/texts/Texts";
import { TextField1 } from "../../components/fields/TextFields";
import { PrimaryButton } from "../../components/buttons/Buttons";
import { Range } from "../../components/range/Range";
import mastercard from "../../assets/images/mastercard.png";
import { Checkbox, Radio } from "@mui/material";
import { useState } from "react";
import AddCardModal from "./AddNewCardModal";
import AutomatedBookingModal from "./AutomatedBookingModal";

export default function BidDetailsModal({ open, setOpen }) {
  const [addCard, setAddCard] = useState(false);
  const handleClose = () => setOpen(false);
  const [automatedBooking, setAutomatedBooking] = useState(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle_big_width}>
          <div className="relative p-4">
            <SmallHeadingText title="Bid Details" />
            <p className="lead fs_12 ">Choose Bid Amount to Expiry Date</p>
            <div className="container p-0">
              <div className="row">
                <div className="col-sm-6">
                  <TextField1 label="Email" labelclassNames="mt-2" />
                </div>
                <div className="col-sm-6">
                  <TextField1 label="Current Price" labelclassNames="mt-2" />
                </div>{" "}
                <div className="col-sm-6">
                  <TextField1 label="Bid Price" labelclassNames="mt-2" />
                </div>{" "}
                <div className="col-sm-6">
                  <TextField1
                    label={`Bid Expiry Date `}
                    labelclassNames="mt-2"
                    labelIcon={
                      <i className="fa-solid fa-circle-info ms-1 third_txt"></i>
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <Range
                    label="Stretch Range"
                    labelclassNames="mt-4"
                    labelIcon={
                      <i className="fa-solid fa-circle-info ms-1 third_txt"></i>
                    }
                  />
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="d-flex align-items-center justify-content-between my-3 ">
                  <p className={` fs_11 fw_700 `}> Pay with Saved Cards</p>
                  <div className="d-flex align-items-center primary_txt pointer ">
                    <i className="fa-solid fa-plus fs_11 fw_700"></i>
                    <SmallText
                      onClick={() => {
                        setAddCard(true);
                        handleClose();
                      }}
                      title="Add Card"
                      classNames={`${"fw_700"} ms-1 `}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="border_style_1 br_4 p-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <Radio />
                        <div>
                          <SmallText
                            title="Diana Williamson"
                            classNames={`${"fw_700"} ms-1 `}
                          />{" "}
                          <p className="lead fs_11 dimBorder_texxt ">Xxx002</p>
                        </div>
                      </div>
                      <div>
                        <img
                          style={{ width: "20px" }}
                          src={mastercard}
                          alt="mastercard"
                        />
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-sm-6">
                  <div className="border_style_1 br_4 p-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <Radio />
                        <div>
                          <SmallText
                            title="Diana Williamson"
                            classNames={`${"fw_700"} ms-1 `}
                          />{" "}
                          <p className="lead fs_11 dimBorder_texxt ">Xxx002</p>
                        </div>
                      </div>
                      <div>
                        <img
                          style={{ width: "20px" }}
                          src={mastercard}
                          alt="mastercard"
                        />
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-sm-6 mt-4 ">
                  <div className="border_style_1 br_4 p-2">
                    <p className={`fs_11 fw_500 `}>
                      Bid Amount{" "}
                      <i className="fa-solid fa-circle-info ms-1 third_txt"></i>
                    </p>
                    <SmallText
                      title="Avg. Per Person $1333.33"
                      classNames={`${"fw_700"} primary_txt `}
                    />
                    <p className="fs_10">$4000.0</p>
                  </div>
                </div>{" "}
                <div className="col-sm-6 mt-4 ">
                  <div className="d-flex align-items-center">
                    <Checkbox sx={{ marginLeft: "-10px" }} size="small" />
                    <div className="d-flex align-items-center">
                      {" "}
                      <p className="lead fs_12 ">I agree with</p>
                      <SmallText
                        title="Terms & Conditions"
                        classNames={`${"fw_700"} ms-1 primary_txt `}
                      />
                    </div>
                  </div>
                  <PrimaryButton
                    onClick={() => {
                      setAutomatedBooking(true);
                      handleClose();
                    }}
                    title="Bid Now"
                    classNames="w-100 "
                  />
                </div>
              </div>
            </div>

            <i
              className="fa-solid fa-xmark cross_icon_modal"
              onClick={handleClose}
            ></i>
          </div>
        </Box>
      </Modal>{" "}
      <AddCardModal open={addCard} setOpen={setAddCard} />
      <AutomatedBookingModal
        open={automatedBooking}
        setOpen={setAutomatedBooking}
      />
    </div>
  );
}
