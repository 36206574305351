import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle } from "./modalUtitlities";
import "./Modals.css";
import { SmallHeadingText } from "../../components/texts/Texts";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/Buttons";
import autamted_booking from "../../assets/images/automated_booking.png";

export default function AutomatedBookingModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <div className="text-center p-4">
            <img src={autamted_booking} alt="automated_booking" />
            <SmallHeadingText
              title="Automated Booking Alert"
              classNames="fw_700 mt-3"
            />
            <p className="lead fs_12 mt-2">
              When your bid matches the current ticket price, your ticket will
              be instantly booked.
            </p>
            <div className="row ">
              <div className="col-sm-6 mt-4 p-1">
                <PrimaryButton
                  title="Yes"
                  classNames="w-100"
                  onClick={handleClose}
                />
              </div>
              <div className="col-sm-6 mt-4 p-1">
                <SecondaryButton
                  title="No"
                  classNames="w-100"
                  onClick={handleClose}
                />
              </div>
            </div>
            <i
              className="fa-solid fa-xmark cross_icon_modal"
              onClick={handleClose}
            ></i>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
