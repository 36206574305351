import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Modalstyle } from "./modalUtitlities";
import "./Modals.css";
import { SmallHeadingText } from "../../components/texts/Texts";
import { TextField1 } from "../../components/fields/TextFields";
import { PrimaryButton } from "../../components/buttons/Buttons";

import { Checkbox } from "@mui/material";
import OtpModal from "./OtpModal";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function SignupModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [otp, setOtp] = React.useState(false);

  // console.log("hy");
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          <div className="relative p-4">
            <div style={{ maxHeight: "80vh", overflow: "auto" }}>
              <SmallHeadingText title="Sign Up" />
              <p className="lead fs_12">
                Please fill out the following information to create your account
              </p>
              <TextField1 label="First Name" labelclassNames="mt-2" />
              <TextField1 label="Last Name" labelclassNames="mt-2" />
              <TextField1 label="Email" labelclassNames="mt-2" />
              <p className={` fs_11 fw_700 mb-1 mt-2`}>Phone Number</p>
              <div className="relaive textFIeld1 p- d-flex align-items-center ">
                <div
                  style={{ gap: "10px" }}
                  className="number_selctor d-flex align-items-center"
                >
                  {" "}
                  <p className={` fs_11 fw_700 `}>+91</p>
                  <i
                    style={{ fontSize: "8px" }}
                    className="fa-solid fa-chevron-down "
                  ></i>
                </div>
                <input className={` textfield_phone_number w-100`} />
              </div>
              <TextField1
                label="Password"
                type={showPassword === true ? "text" : "password"}
                labelclassNames="mt-2"
                iconDiv={
                  <div
                    className="icon_div pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword === true ? (
                      <i className="fa-regular fa-eye-slash"></i>
                    ) : (
                      <i className="fa-sharp fa-regular fa-eye"></i>
                    )}
                  </div>
                }
              />
              <TextField1
                label="Confirm Password"
                type={showPassword === true ? "text" : "password"}
                labelclassNames="mt-2"
                iconDiv={
                  <div
                    className="icon_div pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword === true ? (
                      <i className="fa-regular fa-eye-slash"></i>
                    ) : (
                      <i className="fa-sharp fa-regular fa-eye"></i>
                    )}
                  </div>
                }
              />
              <div
                style={{ gap: "2px" }}
                className="d-flex align-items-center mt-2"
              >
                {" "}
                <Checkbox
                  {...label}
                  size="small"
                  sx={{
                    color: `var(--primary)`,
                    "&.Mui-checked": {
                      color: `var(--primary)`,
                    },
                  }}
                />
                <p className="lead fs_12">I agree wtih</p>{" "}
                <p className={` fs_11 fw_700 primary_txt  `}>
                  Terms & Conditions
                </p>
              </div>

              <PrimaryButton
                onClick={() => {
                  setOtp(true);
                  handleClose();
                }}
                title="Sign Up"
                classNames="w-100 mt-3"
              />
              <div
                style={{ gap: "2px" }}
                className="d-flex align-items-center justify-content-center mt-3"
              >
                {" "}
                <p className="lead fs_12">Already have an account?</p>{" "}
                <p className={` fs_11 fw_700 primary_txt  `}>Sign in</p>
              </div>
            </div>
            <i
              className="fa-solid fa-xmark cross_icon_modal"
              onClick={handleClose}
            ></i>
          </div>
        </Box>
      </Modal>{" "}
      <OtpModal modalopen={otp} setModalOpen={setOtp} />
    </div>
  );
}
