import React from "react";
import "./SideBar.css";
import { SideBarMenu } from "../../pages/StaticData";
import active_arrow from "../../assets/images/active_arrow.png";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomSwitch } from "../switch/CustomSwitch";

const SideBar = () => {
  const { pathname } = useLocation();
  //   console.log(pathname);
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex align-items-center justify-content-center flex-column p-2">
        <div
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          className="relative mt-3"
        >
          <img
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg"
            alt="profile"
          />
          <div style={{ marginTop: "1px" }} className="cam_icon third_txt_bg">
            <i className="fa-solid fa-camera  third_txt fs_10 text-white"></i>
          </div>
        </div>
        <p className="mt-3 text-center fw_500">ALice Butler</p>
        <p className="fs_11 dim_txt  text-center    ">AliceButtler@gmail.com</p>
      </div>

      <hr className="m-2" />

      {SideBarMenu.map((data, i) => (
        <div key={i}>
          <div
            onClick={() => navigate(data.path)}
            style={{ gap: "15px" }}
            className="d-flex align-items-center p-2 relative pointer"
          >
            <div
              className={` ${
                pathname === data.path
                  ? "active_side_bar_icon  text-white"
                  : "third_txt non_active_icon_bg"
              }   siderbar_icon_div  ms-4`}
            >
              {data.icon}
            </div>{" "}
            <p
              className={` ${
                pathname === data.path ? "fw_700" : "fw_500"
              }  fs_12 `}
            >
              {" "}
              {data.title}{" "}
            </p>
            <img
              src={active_arrow}
              alt="active_image"
              className={pathname === data.path ? "active_arrow" : "d-none"}
            />
            {data.path === "/dashboard/notifications" ? <CustomSwitch /> : ""}
          </div>
        </div>
      ))}
    </>
  );
};

export default SideBar;
