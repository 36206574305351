export const SmallBoldText = ({ title, classNames, onClick }) => {
  return (
    <p
      onClick={onClick && onClick}
      className={`${classNames && classNames} fw-bold fs_12`}
    >
      {" "}
      {title}
    </p>
  );
};

export const SmallText = ({ title, classNames, onClick }) => {
  return (
    <p
      onClick={onClick && onClick}
      className={`${classNames && classNames}  fs_12`}
    >
      {" "}
      {title}
    </p>
  );
};

export const SmallHeadingText = ({ title, classNames }) => {
  return <h5 className={`${classNames && classNames}  `}> {title}</h5>;
};

export const MediumHeadingText = ({ title, classNames }) => {
  return <h4 className={`${classNames && classNames} fw_700  `}> {title}</h4>;
};
