import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { GraphData } from "../../StaticData";

const BidsGraph = ({ pause }) => {
  return (
    <div
      className={`${pause === true && "not_active"}`}
      style={{ overflow: "auto" }}
    >
      <LineChart
        width={500}
        height={300}
        data={GraphData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="transparent" />
        <XAxis
          dataKey="name"
          tick={{ fontSize: 12, stroke: "white" }}
          axisLine={{ stroke: "white" }}
        />
        <YAxis
          tick={{ fontSize: 12, stroke: "white" }}
          axisLine={{ stroke: "white" }}
        />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#12b3a8"
          strokeWidth={3} // Adjust the line width here (e.g., increase to 2)
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </div>
  );
};

export default BidsGraph;
