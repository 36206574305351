import React from "react";
import logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux";
import { SmallBoldText } from "../texts/Texts";
import { Avatar, ClickAwayListener } from "@mui/material";
import { useState } from "react";
import { PrimaryButton } from "../buttons/Buttons";
import LoginModal from "../../pages/modals/LoginModal";
import SignupModal from "../../pages/modals/SignUpModal";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const user = useSelector((state) => state.user.user);
  const [signUpModal, setSignUpModal] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const { pathname } = useLocation();
  //   console.log(pathname === "/");

  const navigate = useNavigate();
  return (
    <>
      {" "}
      <div className="d-flex align-items-center justify-content-between">
        <img
          className="pointer"
          src={logo}
          alt="logo"
          width="100px"
          onClick={() => navigate("/")}
        />
        {user === null ? (
          <div style={{ gap: "10px" }} className="d-flex align-items-center">
            <SmallBoldText
              classNames="pointer"
              title="Sign Up "
              onClick={() => setSignUpModal(true)}
            />
            <PrimaryButton title="Login" onClick={() => setLoginModal(true)} />
          </div>
        ) : (
          <div style={{ gap: "10px" }} className="d-flex align-items-center">
            <div
              onClick={() => navigate("/dashboard/notifications")}
              className="bell_icon_div pointer"
            >
              <i className="fa-regular fa-bell text-white"></i>
            </div>
            <div
              style={{
                width: "1px",
                background: "#1a3948",
                height: "30px",
              }}
            ></div>
            <ClickAwayListener onClickAway={() => setShowDrop(false)}>
              <div>
                <div
                  onClick={() => setShowDrop(!showDrop)}
                  style={{ gap: "10px" }}
                  className="d-flex align-items-center relative pointer"
                >
                  <Avatar
                    sx={{ width: 37, height: 37 }}
                    alt="Remy Sharp"
                    src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  />
                  <i
                    className={`fa-solid ${
                      showDrop === true ? "fa-chevron-up" : "fa-chevron-down"
                    }  fs_11 ${pathname === "/" ? "text-white" : ""}`}
                  ></i>
                </div>
                {showDrop === true && (
                  <div className=" profile_drop_cont shadow-sm bg-white rounded">
                    <SmallBoldText
                      onClick={() => navigate("/dashboard/profile")}
                      title="My Profile"
                      classNames=" pointer"
                    />

                    <SmallBoldText
                      onClick={() => navigate("/dashboard/my-bids")}
                      title="My Bids"
                      classNames="fw_500 pointer mt-1"
                    />
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </div>
        )}
      </div>
      <LoginModal open={loginModal} setOpen={setLoginModal} />
      <SignupModal open={signUpModal} setOpen={setSignUpModal} />
    </>
  );
};

export default Header;
