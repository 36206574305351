import "./Select.css";

export const CustomSelect = ({
  label,
  labelclassNames,
  optionValue,
  labelIcon,
}) => {
  return (
    <>
      {" "}
      <p className={`${labelclassNames && labelclassNames} fs_11 fw_700 mb-1`}>
        {label}
        {labelIcon && labelIcon}
      </p>
      <select className="form-select" aria-label="Default select example">
        {/* <option selected>Open this select menu</option> */}

        {optionValue}
      </select>
    </>
  );
};
