import React from "react";
import { MediumHeadingText } from "../../components/texts/Texts";
import google_button from "../../assets/images/google_button.png";
import paystore_btn from "../../assets/images/paystore_btn.png";

const DownLoadApp = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row pt-2">
          <div
            style={{ borderRadius: "8px" }}
            className="col-xl-11 mx-auto green_bg"
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-7 py-4 d-flex flex-column justify-content-center">
                  {" "}
                  <p className="text-white lead fs_12 opac_07 mb-2">
                    DOWNLOAD APP NOW !
                  </p>
                  <MediumHeadingText
                    title="Get the app on Play Store & App Store"
                    classNames="text-white"
                  />{" "}
                  <p className="fs_12 dimBorder_texxt lead font-italic pt-2">
                    Stay connected to your fight bid with Trippy Bids App.
                    Download now & embark on a journey to optimal well-being!
                  </p>
                  <div className="d-flex align-items-center mt-4">
                    {" "}
                    <img
                      src={google_button}
                      alt="google_bt"
                      style={{
                        width: "150px",
                        height: "69px",
                        marginLeft: "-10px",
                      }}
                    />{" "}
                    <img
                      src={paystore_btn}
                      alt="google_bt"
                      style={{
                        width: "150px",
                        height: "65px",
                        marginTop: "-10px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-5 d-flex justify-content-end relative">
                  <img
                    src="https://images.pexels.com/photos/799443/pexels-photo-799443.jpeg?auto=compress&cs=tinysrgb&w=600"
                    style={{ width: "100px" }}
                    className="my-4"
                    alt="download_app"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownLoadApp;
