import React from "react";
import "./App.css";
import "./color.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import FlightList from "./pages/flightList/FlightList";
import FlighDetails from "./pages/flightDetails/FlighDetails";
import HotDestinations from "./pages/hotDestinations/HotDestinations";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import SideBar from "./components/sideBar/SideBar";
import Profile from "./pages/dashboard/Profile/Profile";
import MyBids from "./pages/dashboard/myBids/MyBids";
import Payment from "./pages/payment/Payment";

const DashboardLayout = () => {
  return (
    <>
      {" "}
      <div className="container mt-3">
        <div className="row relative">
          <Header />
        </div>
      </div>
      <div className="cont_bg">
        <div className="container mt-5">
          <div className="row">
            <div className="col-sm-3">
              <div className="border_style_1  br_4">
                <SideBar />
              </div>
            </div>
            <div className="col-sm-9">
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/dashboard/profile" />}
                />
                <Route path="profile" element={<Profile />} />
                <Route path="my-bids" element={<MyBids />} />
                <Route path="faq" element={<DashboardFAQ />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const DashboardFAQ = () => {
  return <h2>Dashboard FAQ</h2>;
};

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/flightList" element={<FlightList />} />
        <Route path="/flightDetails" element={<FlighDetails />} />
        <Route path="/hotDestinations" element={<HotDestinations />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/dashboard/*" element={<DashboardLayout />} />{" "}
      </Routes>
    </>
  );
};

export default App;
