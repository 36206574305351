import React from "react";
import { MediumHeadingText } from "../../../components/texts/Texts";
import { Grid } from "@mui/material";
import { TextField1 } from "../../../components/fields/TextFields";
import { CustomSelect } from "../../../components/select/Selects";
import { DefaultSelectData } from "../../StaticData";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/buttons/Buttons";
import { useState } from "react";

const Profile = () => {
  const [edit, setEdit] = useState(false);
  return (
    <div className="border_style_1 p-3 br_4">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <MediumHeadingText title="Personal Information" />
        <SecondaryButton title="Edit" onClick={() => setEdit(!edit)} />
      </div>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12} sm={6}>
          <TextField1 label="First Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField1 label="Last Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField1 label="Email" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <p className={` fs_11 fw_700 mb-1 `}>Phone Number</p>
          <div className="relaive textFIeld1 p- d-flex align-items-center ">
            <div
              style={{ gap: "10px" }}
              className="number_selctor d-flex align-items-center"
            >
              {" "}
              <p className={` fs_11 fw_700 `}>+91</p>
              <i
                style={{ fontSize: "8px" }}
                className="fa-solid fa-chevron-down "
              ></i>
            </div>
            <input className={` textfield_phone_number w-100`} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField1 label="Age" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField1 label="Address" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField1 label="City" />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <TextField1 label="State" /> */}
          <CustomSelect
            label="State"
            optionValue={DefaultSelectData.map((data, i) => (
              <option key={i} value={i}>
                {data}
              </option>
            ))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField1 label="Zip" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomSelect
            label="Country"
            optionValue={DefaultSelectData.map((data, i) => (
              <option key={i} value={i}>
                {data}
              </option>
            ))}
          />
        </Grid>
        {edit === true && (
          <Grid item xs={12} sm={4}>
            <PrimaryButton
              title="Save"
              classNames="w-100 my-5"
              onClick={() => setEdit(!edit)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Profile;
